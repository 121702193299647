.Root {
  margin-top: 5%;
  margin-left: 5%;
  display: flex;
  height: 700px;
  // border: 1px solid red;
}

.TabRoot {
  margin-left: 5%;
  margin-right: 5%;
  width: 100%;
  height: 600px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: none;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.TabTitle {
  color: rgba(0, 0, 0, 0.7);
  margin: 2%;
  font-size: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.7);
}
