@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

.Root {
  margin-top: 4%;
  margin-bottom: 3%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  h1,
  h2 {
    margin: 0;
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Space Grotesk', sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }

  h2 {
    margin-bottom: 2%;
  }

  img {
    height: 500px;
    width: auto;
  }
}
