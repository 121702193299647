@media (max-width: 1200px) {
  .Tags {
    // flex-direction: column;
  }
}

.Tags {
  display: flex;
}

.Tag {
  margin: 5px;
  padding: 5px;
  width: 220px;
  display: flex;
  border-radius: 0.4rem;
  border: 1px solid rgba(166, 166, 166, 0.6);
  align-items: center;

  img {
    margin-right: 15px;
    width: 30px;
    height: auto;
    opacity: 0.4;
  }
}

.TagText {
  h1 {
    margin-top: 0;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 3px;
  }
}

.TagForWhoText {
  font-weight: 500;
  margin-top: 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 11px;
}

.FifficultyTextP {
  p {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
  }
}
