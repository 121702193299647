@import '../../../../components/common/colors.scss';

.CourseItem {
  position: ab;
}

.Progress {
  margin-top: 3%;
  margin-left: 2%;
  margin-right: 2%;
}

.CourseName {
  h1 {
    margin: 1% 0% 0% 2%;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
    color: rgb(0, 0, 0, 0.8);
  }
}

.CourseAuthor {
  display: flex;
  align-items: center;

  h1 {
    color: rgb(0, 0, 0, 0.8);
    text-align: left;
    margin-left: 5px;
    margin-bottom: 0;
    font-size: 13px;
  }
}

.CourseUpdates {
  text-align: left;
  margin-left: 2%;

  h1 {
    margin: 0;
    font-size: 11px;
    color: rgb(0, 0, 0, 0.8);
  }
}

.BottomButtons {
  display: flex;
  margin: 2%;

  button:hover {
    cursor: pointer;
    background-color: $footer;
    color: white;
    transition: 0.5s;
  }

  button {
    transition: 0.5s;
    border: 1px solid rgb(0, 0, 0, 0.5);
    color: black;
    background-color: white;
  }
}

.ButtonRedirectToVideo {
  width: 100%;

  button {
    height: 40px;
    width: 100%;
  }
}
.CourseGeneralInformation {
  padding: 2%;
}

.ButtonOpenSettings {
  width: 20%;
  height: 40px;
  margin-left: 2%;
}

.CourseItem {
  margin: 10px 10px;

  width: 350px;
  height: 270px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-left: 5%;
}

.CoursePreview:hover {
  transition: 0.3s;
  opacity: 0.95;
}
.CoursePreview {
  transition: 0.3s;
  width: 100%;
  height: 160px;
  object-fit: cover;
  margin-bottom: 0;
}

.CourseContextWrapper {
  height: 100%;

  &__Content {
    display: flex;
    flex-wrap: wrap;
    margin-left: 5%;
  }

  &__Empty button:hover {
    cursor: pointer;
    transition: 0.5s;
    background-color: $footer;
  }

  &__Empty {
    text-align: center;
    height: 100%;
    text-align: center;
    justify-content: center;
    margin-top: 10%;

    button {
      transition: 0.5s;
      margin-top: 1%;
      background-color: $module-color;
      color: white;
      border: none;
      width: 200px;
      height: 40px;
      font-weight: bold;
    }

    h1 {
      color: rgb(0, 0, 0, 0.4);
    }
  }
}

.CourseProgress {
  margin: 2%;
  display: flex;
  align-items: center;

  h4 {
    font-size: 13px;
    padding: 11px 3px 11px 3px;
    border-radius: 2rem;
    color: rgb(0, 0, 0, 0.8);
    border: 1px solid rgb(0, 0, 0, 0.8);
  }

  button {
    font-size: 13px;
    height: 35px;
  }
}
