@import '../../components/common/buttons.scss';

.MentorNested {
  // border: 1px solid red;
  margin-left: 10%;
  margin-right: 10%;
}

.BottomPageButton {
  justify-content: center;
  display: flex;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
  button {
    @include ButtonFontWeightStyle(300px, 50px, 500);
    background-color: #121824;
    color: white;
  }
}
