@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');

.Root {
  margin: 50px 5% 5% 5%;
  display: flex;
  // border: 1px solid red;

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.News {
  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.01),
    0 17px 50px 0 rgba(0, 0, 0, 0.01);

  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  button:hover {
    cursor: pointer;
  }
  button {
    margin-left: 20%;
    margin-right: 20%;
    height: 50px;
    background-color: white;
    border: none;
  }
  P {
    margin: 0;
    font-family: 'EB Garamond', serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 19px;
    text-align: left;
  }

  h1 {
    font-family: 'EB Garamond', serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    margin: 0;
  }
}

.MainNews {
  margin-bottom: 5%;

  h2 {
    font-family: 'EB Garamond', serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-size: 15px;
    margin: 0;
  }

  h1 {
    font-family: 'EB Garamond', serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    margin: 0;
  }

  p {
    margin: 0;
    font-family: 'EB Garamond', serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
}

.Main {
  // border: 1px solid red;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
}

.MainNews {
  position: relative;
  text-align: center;
  display: flex;

  p {
    margin-left: 5%;
    margin-right: 5%;
  }

  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      margin-top: 1rem;
    }
  }
}

.ImageContainer {
  img {
    object-fit: cover;
    width: 600px;
    height: 300px;
    display: block;
  }
}

.SocialMediaRoot {
  height: auto;
  // border: 1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    // border: 2px solid rgba(0, 0, 0, 0.1);
    margin-left: 1%;
    margin-right: 1%;

    img {
      padding-top: 5px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 5px;
      height: 30px;
      width: auto;
    }
  }
}

.NewsTextWrapper {
  // margin-left: 2rem;
  // text-align: left;
}
