@import '../../../../components/common/buttons.scss';

.Root {
  margin-top: 5%;
  margin-left: 5%;
  display: flex;
  min-height: 600px;
}
.UserWishList {
  width: 100%;
}

.EmptyWishList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: 'Times New Roman', Times, serif;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
  }

  button {
    @include ButtonFontWeightInterStyle(300px, 50px, 600);
  }
}
