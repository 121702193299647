.Filter {
  margin-right: 30px;
}
.checkboxWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 3%;
  position: relative;
  cursor: pointer;
}

.checkboxWrapper input[type='checkbox'] {
  transition: 0.4s;
  -webkit-appearance: none;
  appearance: none;
  width: 1.6em;
  height: 1.6em;
  border-radius: 0rem;
  margin-right: 0.5em;
  border: 0.15em solid #122432;
  outline: none;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
}

.checkboxWrapper input[type='checkbox']:checked {
  background-color: #122432;
  transition: 0.4s;
}

.ValuesEmpty {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 3%;
  opacity: 0.3;
  position: relative;
  cursor: pointer;
}

.ValuesEmpty input[type='checkbox'] {
  transition: 0.4s;
  -webkit-appearance: none;
  appearance: none;
  width: 1.6em;
  height: 1.6em;
  border-radius: 0rem;
  margin-right: 0.5em;
  border: 0.15em solid #122432;
  outline: none;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
}

// .ValuesEmpty input[type='checkbox']:checked {
//   background-color: #122432;
//   transition: 0.4s;
// }

.textWrapper {
  margin-left: 2.1em;
  display: flex;
  // flex-direction: column;
  align-items: center;
  width: 100%;
}

.textWrapper h5 {
  color: rgba(0, 0, 0, 0.5);
  margin: 5%;
  font-size: 15px;
}

.textWrapper h4 {
  color: rgba(0, 0, 0, 0.8);
  margin: 5%;
  font-size: 18px;
}

.FilterWrapper {
  width: 300px;

  h1 {
    font-size: 25px;
    color: rgba(0, 0, 0, 0.8);
    margin-left: 5%;
    margin-bottom: 2%;
    margin-top: 4%;
  }
}
