.ActiveCareers {
  height: auto;
  height: 100px;
}

.PageTop {
  // border: 1px solid red;
  display: flex;
  flex-direction: column; /* Stacks items vertically on smaller screens */
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;

  @media (min-width: 768px) {
    flex-direction: row; /* Align items horizontally on larger screens */
    justify-content: space-between;
  }
}

.PageTopText {
  max-width: 600px; /* Adjust the text container width */
  margin-bottom: 20px; /* Add space between the text and image on small screens */

  h1 {
    font-size: 24px; /* Adjust font size for smaller screens */
    @media (min-width: 768px) {
      font-size: 36px; /* Increase font size on larger screens */
    }
  }

  h3 {
    font-size: 18px; /* Adjust font size for smaller screens */
    @media (min-width: 768px) {
      font-size: 24px; /* Increase font size on larger screens */
    }
  }
}

img {
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
  @media (min-width: 768px) {
    width: 40%; /* Shrink image on larger screens */
  }
}

.PageSummary {
  text-align: center;
  margin-bottom: 5%;
  margin-top: 150px;
  font-size: 17px;
  margin-top: 0;
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1,
  h2 {
    margin: 0;
  }
  h2 {
    margin-bottom: 2%;
  }
}
