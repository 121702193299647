@import '../../components/common/buttons.scss';

.Header {
  h3 {
    margin-top: 5px;
    font-size: 25px;
    font-family: 'Times New Roman', Times, serif;
    color: rgba(0, 0, 0, 0.7);
  }
  h1 {
    margin: 0;
    color: rgba(0, 0, 0, 0.8);
  }
}

.Root {
  margin-left: 5%;
  margin-bottom: 5%;
  margin-top: 3%;
  margin-right: 5%;

  h1 {
    font-family: 'Times New Roman', Times, serif;
  }
}

.Main {
  margin-top: 3%;

  &__Wrapper {
    display: flex;
  }
}

.Title {
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 0;
}

.Info {
  display: flex;
  align-items: center;
  // border: 1px solid red;
  height: 50px;

  h2 {
    font-family: 'Times New Roman', Times, serif;
    color: rgba(0, 0, 0, 0.8);
    height: 40px;
    margin-left: 10px;
    font-size: 24px;
    margin-bottom: 0;
  }

  h3 {
    font-family: 'Times New Roman', Times, serif;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
    height: 40px;
    font-size: 16px;
    padding: 0;
    margin: 0;
  }
}

.HeaderWithPrice {
  display: flex;
  justify-content: space-between;

  h2 {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
    font-size: 20px;
    margin-top: 0;
    text-align: end;
  }
}

@media (max-width: 1000px) {
  .Courses {
    width: 100%;
  }
  .Filter {
    display: none;
  }

  .CourseWrapper {
    margin-left: 0%;
  }
}

.SortCoursesButton {
  width: 150px;
  border: none;
  background-color: white;

  & {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  img {
    height: 30px;
    width: auto;
  }
}

.ButtonsWrapper {
  display: flex;
  // border: 1px solid red;
  justify-content: space-between;
}
