@import '../../components/common/buttons.scss';

.Root {
  z-index: 3;
  margin-left: 5%;
  position: relative;
}

.dropdownMenu {
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  transition: all linear 0.3s;
  height: auto;
  width: 400px;
  right: 0;
  position: absolute;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);

  button {
    @include ButtonFontWeightStyle(100%, 50px, 500);
    background-color: #121824;
    color: white;
  }
}

.HeaderCartImage:hover {
  opacity: 1;
  transition: 0.3s;
}

.HeaderCartImage {
  transition: 0.3s;
  opacity: 0.7;
  object-fit: cover;
  width: auto;
  height: 25px;
  transition: transform 0.3s ease-in-out;
}

.ShopingCartEmpty {
  height: 150px;
  align-items: center;
  justify-content: center;
  display: flex;

  h1 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 17px;
    font-weight: 400;
  }
}

.CoursePreview {
  height: 80px;
  width: 150px;
  object-fit: cover;
  border-radius: 0.2rem;
}

.CourseDataWrapper {
  margin-left: 1%;
}

.CourseName {
  margin: 0;
  font-size: 14px;
}

.CourseRating {
  //border: 1px solid red;
  // height: 30px;
  margin: 0;
}

.DiscountWrapper {
  display: flex;

  s {
    margin-left: 5px;
  }
}

.TotalPriceWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}

.CartTotalPriceTitle {
  font-size: 15px;
}

.Course {
  margin-bottom: 10px;
  display: flex;
}
