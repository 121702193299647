@import '../../components/common/inputs.scss';
@import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap');

.Title {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
  font-family: 'Zain', sans-serif;
  margin: 0;
}

.CustomInput input {
  @include CustomInputWeightStyle(500px, 50px, 500);
}

.CSVNumber:focus,
.CardNumber:focus {
  outline: none;
}
.CSVNumber,
.CardNumber {
  outline: none;
  border: none;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.CSVNumber {
  width: 100px;
  //border: 1px solid red;
}
