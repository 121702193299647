.EmailInputForm,
.UserInputForm,
.PasswordInputForm {
  border-radius: 0;
  margin-top: 10px;
  width: 500px;
  height: 60px;
  outline: none;

  &:focus {
    outline: none;
  }
}
.CustomFormTitle {
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
  font-size: 15px;
}

.CustomMenuInput {
  margin: 0;
}

.CustomInputForm {
}
