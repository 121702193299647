@import '../common/colors.scss';

.Head {
  margin-top: 0;
  padding: 0;
  width: 100%;
  display: flex;
  background-color: #f0f3fa;
}

.Welcome {
  padding-left: 5%;

  h1 {
    font-size: 20px;
  }
}
