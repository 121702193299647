.DashboardPanel {
  margin-top: 4%;
  margin-left: 2%;
  margin-right: 2%;
  height: 500px;

  border-radius: 0.2rem;
  border: 1px solid rgba(0, 0, 0, 0.2);

  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.Navigate {
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;

  button:hover {
    border: none;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    cursor: pointer;
    transition: 0.5s;
  }

  button {
    transition: 0.5s;
    width: 100px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: bold;
    // border: 1px solid rgba(0,0,0,0.5);
    border: none;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    background-color: white;
  }
}

.activeButton {
  background-color: #4caf50; /* Change to your desired active color */
  color: white; /* Change to your desired text color on active */
}

.ListItemWrapper {
  overflow: auto;
  height: 400px;
}

.General {
  font-family: 'Times New Roman', Times, serif;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    margin-left: 2%;
    margin-right: 2%;
    padding: 10px;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    color: rgba(0, 0, 0, 0.9);
  }

  .studentList {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .student {
    margin-left: 2%;
    margin-right: 2%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 5px;
    font-size: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      color: rgba(0, 0, 0, 0.8);
      margin: 0;
      width: 100%;
      text-align: left;

      &:first-child {
        color: black;
      }
      &:nth-last-child(4) {
        text-align: center;
        margin-left: 2%;
      }

      &:nth-last-child(3) {
        text-align: center;
        margin-left: 8%;
      }

      &:nth-last-child(2) {
        text-align: right;
        padding-right: 7%;
        color: #4caf50;
        font-weight: bold;
      }

      &:last-child {
        text-align: right;
        color: black;
      }
    }

    .name,
    .course,
    .time,
    .status,
    .price {
      flex: 1; /* Equal width for each column */
    }
  }
}
