.Root {
  display: flex;
  flex-direction: column;
  height: 100%;
  a {
    color: black;
    margin-bottom: 1rem;
  }
}

.DrawerBottomRoot {
  margin-top: auto;
  // padding: 16px;
}

.BottomTitle {
  font-size: 15px;
}

.LinksRoot {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // width: 200px;
  // border: 1px solid red;
}

.Link {
  img {
    opacity: 0.6;
    height: 30px;
    width: auto;
  }
}
