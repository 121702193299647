@import '../../../components/common/colors.scss';

.Header {
  display: flex;
  box-shadow: 0px 2px 12px 00px rgba(0, 0, 0, 0.2);
  align-items: center;
  height: 50px;
  justify-content: center;
  border-bottom: 1px solid rgb(0, 0, 0, 0.2);

  a:hover {
    cursor: pointer;
  }

  a {
    left: 0;
    font-weight: bold;
    color: white;
    background-color: $footer;
    text-decoration: none;
    padding: 10px 10px 10px 10px;
  }

  :nth-last-child(2) {
    padding-left: 30px;
    padding-right: 30px;
    margin-left: 1%;
  }

  :last-child {
    padding-left: 30px;
    padding-right: 30px;
    margin-left: 1%;
  }
}

.Header img {
  width: 80px;
  height: 40px;
}
