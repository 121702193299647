@import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@mixin MainButtonStyle($width, $height) {
  transition: 0.3s;
  border: 1px solid #1218245d;
  background-color: white;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
  width: $width;
  height: $height;
  transition: 0.4s;

  &:hover {
    cursor: pointer;
    background-color: #121824;
    color: white;
    transition: 0.3s;
  }
}

@mixin ButtonFontWeightStyle($width, $height, $weight) {
  transition: 0.3s;
  border: 1px solid #1218245d;
  background-color: white;
  font-weight: $weight;
  color: rgba(0, 0, 0, 0.8);
  width: $width;
  height: $height;
  transition: 0.4s;

  &:hover {
    cursor: pointer;
    background-color: #121824;
    color: white;
    transition: 0.3s;
  }
}

@mixin ButtonFontWeightInterStyle($width, $height, $weight) {
  transition: 0.3s;
  border: 1px solid #1218245d;
  background-color: white;
  font-weight: $weight;
  color: rgba(0, 0, 0, 0.7);
  width: $width;
  height: $height;
  transition: 0.4s;
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

  &:hover {
    cursor: pointer;
    background-color: #121824;
    color: white;
    transition: 0.3s;
  }
}
