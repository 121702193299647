@import '../common/colors.scss';

.Root {
  background-color: $footer;
}

@media (max-width: 600px) {
  .Footer {
    flex-direction: column;
  }

  .SocialWrapper {
    display: flex;
    align-items: center;
  }
}

.Link {
  a {
    img {
      padding-right: 0.5rem;
      opacity: 0.7;
      height: 20px;
      width: auto;
      transition: 0.3s;

      &:hover {
        transition: 0.3s;
        opacity: 1;
      }
    }
  }
}

.SocialRoot {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.FooterBottom {
  // border: 1px solid red;
  hr {
    width: 90%;
    border: 1px solid rgba(166, 166, 166, 0.5);
  }
}

.BottomWrapper {
  margin-left: 1%;
  margin-right: 6%;
  justify-content: space-between;
  height: 50px;
  display: flex;
}

.FooterLogo {
  margin-left: 5%;
  height: 50px;
  width: auto;
}

.FooterNavigationLinks a:hover {
  color: white;
  transition: 0.3s;
}
.FooterNavigationLinks a {
  transition: 0.3s;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}
.FooterNavigationLinks {
  // border: 1px solid red;
  padding-top: 2%;
  margin-left: 10%;
  margin-right: 15%;
  height: 250px;
  display: grid;
  grid-template-columns: repeat(3, 0.3fr);
  grid-template-rows: repeat(4, 50px);

  @media (max-width: 600px) {
    margin-right: 0;
  }
}
