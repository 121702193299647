@import '../../../../components/common/buttons.scss';

.Root {
  margin-top: 3%;
  margin-left: 2%;
  //  border: 1px solid red;
  height: 300px;
}

.ProfileRoot {
}

.UploadRoot {
  // border: 1px solid red;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  flex-direction: column;
}

.UploadImageButton {
  @include ButtonFontWeightInterStyle(350px, 50px, 600);
}

.ImageDivRoot {
  display: flex;
}
.TitleText {
  margin-left: 3%;
  color: rgba(0, 0, 0, 0.8);
}

.imageSketelon {
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: 400px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    color: rgba(0, 0, 0, 0.5);
  }
}

.SaveButton {
  margin-top: 10px;
  @include ButtonFontWeightInterStyle(200px, 40px, 600);
}

.PreferencesChecks {
  margin-top: 2%;
  width: 100%;
  height: auto;
  // border: 1px solid red;
}
