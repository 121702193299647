@import '../../../components/common/colors.scss';

.Complete {
  button:hover {
    cursor: pointer;
  }

  button {
    font-weight: bold;
    color: white;
    width: 200px;
    height: 40px;
    background-color: $footer;
    border: none;
  }

  h4 {
    width: 70%;
  }

  &__underline:hover {
    cursor: pointer;
  }

  &__underline {
    text-decoration: underline;
  }
}
