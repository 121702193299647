@import '../../../components/common/colors.scss';

.UserInformationWrapper {
  margin-left: 3%;
  width: 25%;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  // box-shadow: 1px 1px 11px 1px black;
  padding: 10px;

  img {
    object-fit: cover;
    border: 1px solid rgba(0, 0, 0, 0.3);
    margin-top: 10%;
    border-radius: 0.5rem;
    width: 150px;
    height: 150px;
  }

  button:hover {
    transition: 0.2s;
    border-left: 5px solid $footer;
    background-color: rgb(230, 167, 243, 0.2);
    cursor: pointer;
  }

  button {
    transition: 0.5s;
    width: 80%;
    height: 30px;
    background-color: white;
    color: black;
    border: none;
    font-size: 13px;
    font-weight: bold;
  }

  &__Social {
    margin-top: 5%;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    &__Link {
      display: flex;
      input {
        height: 30px;
        font-size: 15px;
      }

      img {
        padding: 10px;
        width: 30px;
        height: auto;
      }
    }
  }
}

.User {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 16px;
  }

  h3 {
    margin-top: 0;
    width: 40%;
    background-color: #24262b;
    color: white;
    padding-top: 2%;
    padding-bottom: 2%;
    border-radius: 0.4rem;
    font-size: 13px;
  }
}

.StateWrapper {
  margin-left: 3%;
  text-align: end;
  margin-right: 3%;
  width: 100%;
  border: 1px solid red;

  h1 {
    font-size: 40px;
    margin-bottom: 0px;
    color: black;
  }

  h3 {
    font-size: 22px;
    margin-top: 0px;
  }

  &__Context {
    margin-top: 1%;
    width: 100%;

    h4 {
      font-family: 'Times New Roman', Times, serif;
      text-align: left;
      margin-left: 2%;
      color: rgba(0, 0, 0, 0.8);
      font-size: 30px;
    }
  }

  &__item {
    align-items: center;
    margin-top: 5%;
    margin-left: 1%;
    transition: 0.5s;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    button {
      height: 60px;
      width: 100%;
    }
  }

  &__ControllButtons {
    li {
      display: flex;
      align-items: center;
    }

    li img {
      width: 15px;
      height: 15px;
    }

    li button:hover {
      cursor: pointer;
      transition: 0.5s;
    }

    li button {
      font-size: 16px;
      transition: 0.5s;
      color: black;
      background-color: rgb(0, 0, 0, 0);
      border: none;
      margin-left: 1%;

      font-weight: bold;
    }

    ul {
      list-style: none;
      padding: 0;
    }
  }
}

.Hrline {
  margin-top: 0;
  margin-left: 5%;
  margin-right: 5%;
}

.CourseContextWrapper {
  height: 100%;

  &__Empty button:hover {
    cursor: pointer;
    transition: 0.5s;
    background-color: $footer;
  }

  &__Empty {
    text-align: center;
    height: 100%;
    text-align: center;
    justify-content: center;
    margin-top: 10%;

    button {
      transition: 0.5s;
      margin-top: 1%;
      background-color: $module-color;
      color: white;
      border: none;
      width: 200px;
      height: 40px;
      font-weight: bold;
    }

    h1 {
      color: rgb(0, 0, 0, 0.4);
    }
  }
}

.Wrapper {
  margin-bottom: 10%;
  display: flex;
  border-radius: 1rem;
  text-align: center;
  justify-content: space-between;
  height: 600px;
}

.Account {
  display: flex;
  justify-content: space-around;
  text-align: left;

  &__Personal,
  &__Social {
    input {
      font-size: 17px;
      font-weight: bold;
      height: 35px;
      outline: none;
    }
  }

  h1 {
    font-family: 'Times New Roman', Times, serif;
  }
}

.Dashboard {
  display: flex;
  justify-content: space-around;
}

.Buttons {
  display: flex;

  button:hover {
    transition: 0.5s;
    cursor: pointer;
    background-color: #24262b;
  }

  button {
    font-weight: bold;
    transition: 0.5s;
    margin-left: 2%;
    height: 40px;
    width: 100px;
    background-color: #1e2025;
    color: white;
    border: none;
  }
}

.CourseItem {
  border: 1px solid rgb(0, 0, 0, 0.4);
  flex-direction: column;
  font-size: 11px;
  font-family: 'Times New Roman', Times, serif;
  margin: 10px 10px;
  text-align: center;
  align-items: center;
  display: flex;

  .ButtonsWrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2%;
  }

  h1 {
    margin-left: 5%;
    color: #1e2025;
  }

  button:hover {
    background-color: #1e2025;
    color: white;
    cursor: pointer;
    transition: 0.5s;
  }

  button {
    margin-right: 5px;
    transition: 0.5s;
    font-weight: bold;
    width: 100px;
    height: 40px;
    background-color: white;
    border: 1px solid rgb(0, 0, 0, 0.7);
  }
}

.Drafts {
  h1 {
    font-family: 'Times New Roman', Times, serif;
    text-align: right;
    margin-right: 5%;
    color: rgba(0, 0, 0, 0.8);
  }
}

.activeTab {
  color: red; /* Set your desired text color for the active tab */
}
