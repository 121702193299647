.MotivationTagsRoot {
  margin-top: 10%;
}

.MotivationTags {
  margin-top: 2%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  // border: 1px solid red;
  justify-content: space-between;

  @media (max-width: 1500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.AdvantagesTitle {
  h1 {
    font-family: 'Times New Roman', Times, serif;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
  }
}
.Tag {
  margin-top: 5%;
  width: 400px;
  height: 170px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.1),
    0 17px 50px 0 rgba(0, 0, 0, 0.1);

  hr {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.TagHeader {
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    color: rgba(0, 0, 0, 0.8);
    margin-left: 3%;
    font-size: 20px;
  }
  img {
    height: 40px;
  }
}

.TagText {
  p {
    text-align: center;
    margin: 5%;
    color: rgba(0, 0, 0, 0.8);
    font-size: 17px;
  }
}
