@import '../../components/common/colors.scss';
@import '../../components/common/buttons.scss';

@media (max-width: 600px) {
  .Header__content .dropdown,
  .Header__content .Header__input {
    display: none;
  }

  .logo {
    justify-content: center;
    width: 140px;
    height: auto;
  }

  .Header__content {
    justify-content: center;
  }
}

.SearchCourseButton:hover {
  cursor: pointer;
}
.SearchCourseButton {
  display: none;
}

.Root {
  // position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 90px;
  align-items: center;
  justify-content: center;

  position: relative;
  display: flex;
  box-shadow: 3px 3px 2px 1px rgba(0, 0, 0, 0.1);

  :root {
    --input-width: 250px; /* начальная ширина */
  }

  /* Дополнительные стили для различных медиа-запросов */
  @media (min-width: 1200px) {
    :root {
      --input-width: 350px; /* увеличиваем ширину для больших экранов */
    }
  }

  @media (min-width: 1300px) {
    :root {
      --input-width: 450px; /* увеличиваем еще больше для еще больших экранов */
    }
  }

  @media (max-width: 900px) {
    .SearchCourseButton {
      display: inline;
    }

    .HeaderInput {
      display: none;
    }

    .AuthenticationUser {
      width: 100%;
    }
  }
}

.Header {
  z-index: 5;
}

.AuthenticationUser {
  display: flex;
  width: 300px;
  justify-content: space-around;
  align-items: center;
}

.logo {
  margin-left: 20px;
  width: 200px;
  height: auto;
}

.HeaderLoginButton {
  margin-left: 20px;
  margin-right: 20px;
  @include ButtonFontWeightInterStyle(130px, 45px, 600);
}

.HeaderSignUpButton {
  margin-right: 40px;
  @include ButtonFontWeightInterStyle(130px, 45px, 600);
  background-color: #121824;
  color: white;
}

.AuthButtons {
  display: flex;
}

.HeaderLinks {
  display: flex;
  margin-left: 2%;
  margin-right: 2%;
}
.BusinessLink,
.MentorLink {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.SearchButtonRoot {
  display: none;

  @media (max-width: 900px) {
    display: block;
  }
}

.SearchButton:hover {
  cursor: pointer;
}

.SearchButton {
  background-color: white;
  border: none;

  img {
    opacity: 0.7;
    height: 25px;
    width: auto;
  }
}

.OpenSearchBarButton,
.OpenDrawerButton {
  display: none;
}

.OpenSearchBarButton,
.OpenDrawerButton {
  height: 40px;
  width: auto;
  background-color: white;
  border: none;

  &:hover {
    cursor: pointer;
  }

  img {
    opacity: 0.7;
    height: 30px;
    width: auto;
  }
}

@media (max-width: 900px) {
  .HeaderLinks {
    display: none;
  }
}
@media (max-width: 600px) {
  .OpenSearchBarButton,
  .OpenDrawerButton {
    display: block;
  }
}
