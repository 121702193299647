@import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap');

@mixin CustomInputWeightStyle($width, $height, $weight) {
  border: 1px solid #1218245d;
  background-color: white;
  font-weight: $weight;
  color: rgba(0, 0, 0, 0.8);
  width: $width;
  height: $height;
  outline: none;
  padding: 5px;
  padding-left: 2%;

  &:hover {
    outline: none;
  }
}
