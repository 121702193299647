// @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.Item {
  box-shadow:
    0 5px 9px 0 rgba(0, 0, 0, 0.08),
    0 8px 10px 0 rgba(0, 0, 0, 0.08);

  width: 90%;
  height: 250px;

  border: 1px solid rgba(0, 0, 0, 0.212);
  border-radius: 0.4rem;
  // border: 1px solid red;
  margin: 3%;

  &__review {
    img {
      // quote
      width: auto;
      height: 25px;
      padding-top: 5%;
      padding-left: 10%;
      position: absolute;
      right: 15%;
    }
  }
}

.ItemsSlider {
  margin-top: 2%;
  margin-bottom: 2%;
}

.ReviewText {
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  margin-top: 3%;
  margin-left: 10%;
  margin-right: 10%;
}

.ReviewAuthor {
  margin-left: 3%;
  font-family: 'Times New Roman', Times, serif;

  h2 {
    font-size: 13px;
    opacity: 0.8;
    margin-top: 0;
  }

  h1 {
    margin-bottom: 0;
    font-size: 22px;
  }
}

.ReviewContent {
  margin-top: 3%;
  // border: 1px solid red;
  padding-left: 10%;
  display: flex;
  align-items: center;

  img {
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: 2rem;
  }
}
