@import url('../../components/common/colors.scss');
@import url('../../components/common/buttons.scss');

@media (max-width: 600px) {
  .WallUserHeader {
    margin: 0;
  }
}

.HomeItemsWrapper {
  margin-left: 10%;
  margin-right: 10%;
}

.Home {
  &__Wall {
    width: 100%;

    @media (max-width: 900px) {
      width: 100%;

      &__Announcement {
        width: 65%;
        margin-left: 5%;
      }

      img {
        border-radius: 4rem;
      }
    }
  }

  &__Wall img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 1rem;
    box-shadow:
      0 12px 16px 0 rgba(0, 0, 0, 0.15),
      0 17px 50px 0 rgba(0, 0, 0, 0.14);
  }
}

.HeaderUser {
  background-color: rgba(240, 248, 255, 0.7);
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 20%;

  img {
    width: 60px;
    height: 60px;
    border-radius: 2rem;
    object-fit: cover;
  }
}

.WallUserHeader {
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  margin-top: 3%;

  @media (max-width: 1200px) {
    .HeaderUser {
      display: none;
    }
  }
  @media (max-width: 900px) {
    margin-left: 0;
    margin-right: 0;

    flex-direction: column;
    margin-top: 0;

    h1 {
      font-size: 55px;
    }

    img {
      border-radius: 0rem;
    }
  }
}
