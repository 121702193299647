@import '../../../../../components//common/buttons.scss';

.BottomBuyPanel {
  display: none;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  height: 100px;
  left: 50%;
  bottom: 0;
  margin-bottom: 0%;
  z-index: 5;
  transform: translateX(-50%);
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: 1400px) {
    display: flex;
  }
}

.WishListButton:hover {
  cursor: pointer;
  background-color: #121824;
  color: white;
  transition: 0.3s;
}

.WishListButton {
  margin-right: 1%;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  // font-weight: bold;
  transition: 0.3s;
  border-radius: 0;
  background-color: white;
  border: 1px solid #121824b0;

  img {
    width: 20px;
    height: auto;
  }
}

.ShopingCartButton {
  background-color: white;
  border: none;

  img {
    width: 20px;
    height: auto;
  }
}

.CheckoutButton {
  button {
    @include ButtonFontWeightStyle(200px, 40px, 400);
  }
}

.BuyButtons {
  display: flex;
}

.Prices {
  margin: 0;
  display: flex;
  align-items: center;
  // border: 1px solid red;
  justify-content: center;

  h1 {
    font-size: 23px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 0;
  }

  s {
    color: rgba(0, 0, 0, 0.8);
    margin-top: 0;
    font-size: 14px;
  }
}

.AuthorName {
  color: rgba(0, 0, 0, 0.8);
  @media (min-width: 800px) and (max-width: 1000px) {
    font-size: 20px;
  }
  @media (min-width: 600px) and (max-width: 800px) {
    font-size: 18px;
  }
  @media (max-width: 600px) {
    font-size: 15px;
  }
}
.CourseName {
  margin: 0;
  font-size: 30px;

  @media (min-width: 800px) and (max-width: 1000px) {
    font-size: 20px;
  }
  @media (min-width: 600px) and (max-width: 800px) {
    font-size: 18px;
  }
  @media (max-width: 600px) {
    font-size: 15px;
  }
}

.CourseAuthorRating {
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0;
  height: 30px;

  h4 {
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
  }
}

.CourseRating {
  display: flex;
  align-items: center;
  margin-left: 3%;
  padding: 0;

  h3 {
    color: rgb(255, 145, 0);
  }

  img {
    height: 17px;
    width: auto;
  }
}

.Course {
  h1 {
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.9);
  }
}

.ButtonsPriceWrapper {
}
