@import '../../components/common/colors.scss';
@import '../../components/common/buttons.scss';

.SendingEmailRoot {
  margin-top: 5rem;
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 5%;
}

.CustomerName {
  display: flex;
}

.SendMessageButton {
  margin-top: 2rem;
  @include ButtonFontWeightInterStyle(200px, 40px, 600);
}
