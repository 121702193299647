@import '../../../../components/common/colors.scss';

.Content {
  height: auto;
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 1%;
}

hr {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

@media (min-width: 1450px) {
  .Content {
    width: 55%;
    //  border: 1px solid red;
  }
}
