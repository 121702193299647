.Root {
  margin-top: 5%;
  // border: 1px solid red;
  height: auto;
}

.CategoriesWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 12px;
  width: 100%;
  padding: 16px;

  @media (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, auto);
  }
}

.Title {
  // font-family: 'Times New Roman', Times, serif;
  @media (max-width: 1000px) {
    text-align: center;
  }
}

.Categorie {
  width: 300px;
  height: 200px;
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);

  a:hover {
    transition: 0.3s;
    color: rgba(0, 0, 0, 1);
  }

  a {
    transition: 0.3s;
    color: rgba(0, 0, 0, 0.7);
    font-family: 'Times New Roman', Times, serif;
    text-decoration: none;
  }
}
