@import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap');

.Root {
  // margin-left: 5%;
}

.Title {
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
  font-size: 15px;
}

.TextAreaRoot {
  resize: none; // Prevents resizing
  width: 100%; // Adjust as needed
  height: 150px; // Adjust as needed
  padding: 10px; // Optional: styling for padding
  border: 1px solid #ccc; // Optional: border styling
  outline: none; // Optional: outline styling
}
