.Root {
  display: flex;
}

.TermsSelector {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  height: auto;
}

.TermsCharacterContent {
  width: 100%;
  height: auto;
  margin-left: 5%;
  margin-right: 10%;

  p {
    color: rgba(0, 0, 0, 0.7);
  }
  h1 {
    font-family: 'Times New Roman', Times, serif;
    color: rgba(0, 0, 0, 0.8);
  }
}

.TopicEntity {
  width: 350px;
  button:hover {
    cursor: pointer;
    background-color: beige;
    transition: 0.4s;
  }
  button {
    transition: 0.4s;
    text-align: left;
    padding-left: 10%;
    background-color: white;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 70px;
  }
}
