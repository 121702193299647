@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');
@import '../../components/common/buttons.scss';

.Root {
  // border: 1px solid red;
  margin-left: 10%;
  margin-right: 10%;
  // margin-top: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.AboutUsTitle {
  font-size: 65px;
  font-weight: 400;
  font-family: 'Times New Roman', Times, serif;
}

.CeoRoot {
  display: flex;
  align-items: center;

  h1,
  h2 {
    margin: 0;
  }
}

.CeoAboutWrapp {
  margin-left: 15px;

  h1 {
    font-size: 25px;
  }
  h2 {
    font-weight: 400;
    font-size: 17px;
  }
}

.CeoImage {
  object-fit: cover;
  border-radius: 5rem;
  height: 90px;
  width: 90px;
}

.MessageRoot {
  max-width: 650px;

  h1 {
    font-family: 'EB Garamond', serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 23px;
  }
}

.ButtonsRoot {
  //border: 1px solid red;
  width: 650px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  margin-top: 1rem;

  button {
    @include ButtonFontWeightInterStyle(200px, 50px, 600);

    a {
      color: black;
    }
  }

  @media (max-width: 800px) {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    button {
      margin-top: 1rem;
    }
  }
}
