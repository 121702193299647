@import '../../components/common/colors.scss';
@import '../../components/common/buttons.scss';

.Root {
  width: 700px;

  height: 400px;
  border: 1px solid rgb(166, 166, 166, 0.3);
  margin-left: 2%;
  margin-bottom: 5%;

  box-shadow:
    0 15px 16px 0 rgba(0, 0, 0, 0.1),
    0 27px 60px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 1200px) {
    //width: 100%;
  }
}

.SavePriceWithDiscountTitle {
  margin: 0;
  font-size: 15px;
  color: rgb(0, 0, 0, 0.8);
  text-align: center;
}
.CartItemsAmountTitle {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
}

.CardPaymentForm {
  padding: 2%;

  label {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
  }
}

.PaymentCard {
  // border: 1px solid red;
  padding: 5%;

  display: flex;
  flex-direction: column;

  input:focus {
    outline: none;
  }

  input {
    outline: none;
    width: 100%;
    border: none;
    border-bottom: 1px solid rgb(166, 166, 166);
    height: 30px;
  }
}

.HeaderTitle {
  text-align: center;
  color: rgb(0, 0, 0, 0.8);
}

.TotalPrice {
  // border: 1px solid red;
  margin-left: 8%;
  margin-right: 8%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PriceAlignDiscount {
  display: flex;
  justify-content: space-between;
  margin-left: 8%;
  margin-right: 8%;

  h3 {
    color: green;
    font-size: 14px;
  }
}

.PriceAlign {
  display: flex;
  justify-content: space-between;

  margin-left: 8%;
  margin-right: 8%;
  h3 {
    font-size: 14px;
  }
}

.Price {
  padding: 2%;

  h3 {
    margin: 0;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
  }

  h1 {
    font-size: 20px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
  }
}

.PayPalForm {
  margin: 5%;
  padding: 5%;
  margin-bottom: 12%;
  border: 1px solid rgb(166, 166, 166);

  h1 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
  }
}

.CheckoutButton {
  display: flex;
  align-items: center;
  justify-content: center;

  // button {
  //   @include MainButtonStyle(80%, 50px);
  // }

  button:hover {
    cursor: pointer;
    background-color: #1c2536;
    transition: 0.4s;
  }
  button {
    transition: 0.4s;
    background-color: #121824;
    color: white;
    border-radius: 0.1rem;
    border: none;
    font-size: 16px;
    // font-weight: bold;
    width: 80%;
    height: 50px;
  }
}

.PaymentMethod {
  display: flex;
  justify-content: space-around;

  img {
    width: 110px;
    height: 30px;
    object-fit: cover;
  }

  button:hover {
    cursor: pointer;
    transition: 0.4s;
  }

  button {
    border-radius: 0.3rem;
    border: 1px solid rgb(166, 166, 166);
    background-color: white;
    transition: 0.4s;
    width: 150px;
    height: 40px;
  }
}

.PaymentInputs {
  // border: 1px solid red;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  justify-content: space-around;
}
