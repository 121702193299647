.Root {
  padding: 1rem;
  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.08),
    0 17px 50px 0 rgba(0, 0, 0, 0.08);

  width: 30%;
  height: 700px;
  margin-left: 2%;

  @media (max-width: 1200px) {
    display: none;
  }
}

.SideNewTitle {
  color: black;

  h1 {
    margin: 0;
    font-size: 23px;
  }
}

.SideNews {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  h2 {
    margin: 0;
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
  }
}

.Likes,
.Views,
.Comments {
  display: flex;
  align-items: center;

  h2 {
    margin-left: 3px;
  }

  img {
    opacity: 0.7;
    width: auto;
    height: 16px;
  }
}
