@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

.HeaderAboutCourse {
  color: rgba(0, 0, 0, 0.9);
  border-radius: 0.3rem;

  @media (max-width: 900px) {
    // width: 100%;
  }
}

.Text {
  font-size: 20px;
  font-family: 'Space Grotesk', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
