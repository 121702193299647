.Root {
  display: flex;
  flex-direction: column;
  height: 100%;
  a {
    color: black;
    margin-bottom: 1rem;
  }
}

.DrawerBottomRoot {
  margin-top: auto;
  // padding: 16px;
}

.BottomTitle {
  font-size: 15px;
}
