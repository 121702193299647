.AboutMarketplace {
  margin-top: 5%;
  // border: 1px solid red;
  text-align: center;
  margin-left: 15%;
  margin-right: 15%;

  h1 {
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Times New Roman', Times, serif;
  }

  p {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Times New Roman', Times, serif;
  }
}
