@import '../../components/common/colors.scss';
@import '../../components/common/buttons.scss';
// @import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.ShowReviewsButton {
  margin-top: 2%;
  margin-bottom: 2%;
  @include ButtonFontWeightInterStyle(200px, 60px, 700);
}

.ReviewItem {
  width: 350px;
  height: 250px;

  hr {
    margin-top: 0;
  }

  &__Content {
    p {
      color: rgb(0, 0, 0, 0.8);
      // font-family: 'Montserrat Alternates', sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 14px;
    }
  }

  &__UserImage {
    img {
      width: 50px;
      height: 50px;
      border: 1px solid rgb(0, 0, 0, 0.2);
      border-radius: 3rem;
    }
  }

  &__Information {
    margin-left: 5%;
    margin-top: 0;

    h1 {
      font-size: 15px;
      color: rgb(0, 0, 0, 0.8);
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
    }

    img {
      margin-top: 0;
      width: 15px;
      height: 15px;
    }
  }

  &__User {
    margin: 0;
    height: 80px;
    display: flex;
    align-items: center;
  }
}

.decreaseReview img {
  transform: rotate(180deg);
}

.Root {
  margin-top: 3%;
}

.ReviewList {
  display: grid;
  grid-template-columns: repeat(2, 0.4fr);
  gap: 5px;
}

.ReviewRating {
  display: flex;
  align-items: center;

  h1 {
    font-size: 20px;
    color: rgb(0, 0, 0, 0.9);
  }
  h2 {
    margin-left: 10px;
    color: rgb(0, 0, 0, 0.8);
    font-size: 14px;
  }
}

.decreaseReview {
  border: none;
  background-color: white;

  img:hover {
    cursor: pointer;
    background-color: rgba(226, 109, 63, 0.7);
  }

  img {
    border-radius: 1rem;
    padding: 4px;
    width: 23px;
    height: 20px;
  }
}

.increaseReview {
  border: none;
  background-color: white;

  img:hover {
    cursor: pointer;
    background-color: rgba(139, 207, 113, 0.4);
  }

  img {
    border-radius: 1rem;
    padding: 4px;
    width: 23px;
    height: 20px;
  }
}

.ReviewDate {
  color: rgb(0, 0, 0, 0.4);
  font-size: 11px;
}

.ReviewRatingControl {
  margin-top: 0.5rem;
  border: 1px solid rgba(166, 166, 166, 0.6);
  width: 25%;
  // border-radius: 1rem;
}
