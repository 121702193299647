@import '../../components/common/fonts.scss';

@media (max-width: 1300px) {
  .Root {
    display: none;
  }
}

:root {
  --scrollbar-width: 26px;
  --scrollbar-bg: #f1f1f1;
  --scrollbar-thumb: rgba(39, 33, 38, 0.8);
  --scrollbar-thumb-hover: rgba(0, 0, 0, 0.6);
}

.Root {
  // margin: 5%;
  border-top: 1px solid rgba(0, 0, 0, 0);
  height: 850px;
  min-width: 400px;
  max-width: 800px;
  overflow-y: auto;
}

/* Webkit browsers */
.Root::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

.Root::-webkit-scrollbar-track {
  background: var(--scrollbar-bg);
  border-radius: 15px;
}

.Root::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 150px;
}

.Root::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

.Root::-webkit-scrollbar-button {
  display: none; /* Hide the up and down arrow buttons */
}

/* Firefox */
.Root {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg);
}

/* Edge and Internet Explorer */
.Root {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: thin; /* For Edge */
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg); /* For Edge */
}
.PlaybackVideoButtons {
  button:hover {
    cursor: pointer;
    background-color: aliceblue;
    transition: 0.3s;
  }

  button {
    transition: 0.3s;
    color: rgba(0, 0, 0, 0.8);
    height: 60px;
    font-size: 12px;
    width: 100%;
    background-color: white;
    font-weight: bold;
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.5);
    margin-top: 1%;
  }
}

.CourseCheckMark {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  img {
    width: 19px;
    height: auto;
  }
}

.CourseTypeImage {
  margin-right: 3%;

  img {
    width: 25px;
    height: auto;
    margin-right: 2%;
  }
}

.ModuleComponent {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: left;

  p {
    width: 100%;
  }
}

.TitleCourse {
  background-color: #121824;
  height: 70px;
  padding: 1%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  h3 {
    margin: 0;
    font-size: 12px;
    color: white;
  }
}

.Aspect {
  display: flex;
  justify-content: space-around;

  h3 {
    font-size: 11px;
  }
}
.CourseAspects {
  display: flex;
  flex-direction: column;

  h1 {
    width: 80%;
    color: white;
    font-size: 15px;
  }
}

.WrapperModuleWithHeader {
  h2 {
    padding: 1%;
    @include ApplySansSerifFont(19px, 600, 0.8);
    text-align: center;
  }
}

.ModuleButtonNestedContent {
  h5 {
    font-size: 11px;
    margin: 0;
  }
  p {
    margin: 0;
  }
}
