@import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap');

.CourseCreation {
  margin-top: 10%;
  margin-left: 15%;
  margin-right: 15%;
}

.HowItsWorks {
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  font-size: 25px;
  font-family: 'Times New Roman', Times, serif;
}

.Step {
  margin-top: 5%;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
}

.IllustarteImage {
  height: 400px;
  width: auto;
}

.TextWrapper {
  h1 {
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Times New Roman', Times, serif;
  }

  p {
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Zain', sans-serif;
    font-size: 25px;
    width: 80%;
    padding: 0;
    margin: 0;
  }
}
