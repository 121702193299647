// @import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap');

.dropdownMenu {
  display: flex;
  flex-direction: column;
  width: 400px;
  right: 0;
  justify-content: center;
  align-items: center;
  position: absolute;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
  color: #1c25366b;
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.35);
  transform: translateY(-10px);
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  z-index: 5;
  opacity: 0;
  pointer-events: none;

  button {
    background: #000;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    color: #fff;
    padding: 12px 25px;
    border: none;
    cursor: pointer;
  }

  hr {
    width: 100%;
    border: 1px solid #1c253627;
  }

  ul {
    list-style: none;
    padding: unset;
    margin: unset;
  }

  a:hover {
    transition: 0.3s;
    color: black;
  }
  a {
    transition: 0.3s;
    color: rgba(0, 0, 0, 0.7);
    font-size: 23px;
    font-family: 'Zain', sans-serif;
    font-weight: 300;
    font-style: normal;
    border-radius: 0.1rem;
    text-align: center;
    width: 90%;
    padding: 2%;
  }

  li:hover {
    background: gray;
    cursor: pointer;
    color: #fff;
  }
}

.dropdownMenuVisible {
  z-index: 5;
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.HeaderUserImage {
  position: relative;
}

.DropDownHeaderUser {
  text-align: left;
  margin-left: 2%;

  h5 {
    margin-top: 0;
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
  }
  h3 {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 0;
  }
}

.DropDownHeader:hover {
  cursor: pointer;
}

.DropDownHeader {
  //border: 1px solid red;
  margin: 5%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 70%;
}

.HeaderUserImage img {
  object-fit: cover;
  width: 55px;
  height: 55px;
  border-radius: 2rem;
}

.dropdownMenu {
  // padding: 30px;
  text-align: center;
  hr {
    width: 80%;
  }
}

.DropDownSectionTitle {
  font-size: 17px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
}

.DropDownHeaderImage {
  object-fit: cover;
  width: 120px;
  height: 80px;
  border-radius: 5rem;
}

// .HeaderUserImage:hover .dropdownMenu {
//   opacity: 1;
//   transform: translateY(0);
//   pointer-events: auto; /* Enable interaction when visible */
// }
