.Root {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 5%;
  display: flex;
}

.Main {
  width: 100%;
}

.PostLikes {
  margin: 0;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid red;
  width: 130px;
}

.Likes,
.Views,
.Comments {
  display: flex;
  align-items: center;

  h2 {
    margin-left: 3px;
  }

  img {
    opacity: 0.7;
    width: auto;
    height: 16px;
  }
}
