@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
.Root {
  margin-left: 10%;
  margin-right: 10%;
  // border: 1px solid red;

  @media (max-width: 1500px) {
    margin: 0;
  }
}

.News {
  margin-top: 2%;
  position: relative;
  width: 100%;
  height: 450px;

  @media (max-width: 1500px) {
    margin-top: 0%;
  }
}

.NewsTextWrapper {
  // border: 1px solid red;
  position: absolute;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-style: normal;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(0, 0, 0, 1);
  text-align: left;
  width: 100%;

  h1,
  h4 {
    margin-left: 5%;
    margin-top: 0;
    margin-bottom: 0;
  }
  h1 {
    width: 50%;
    @media (min-width: 300px) {
      font-size: 23px;
    }
  }
}

.BackgroundImage {
  width: 100%;
  max-width: 100%;
  height: 450px;
  object-fit: cover;
  object-position: 50% 50%;

  @media (min-width: 1000px) {
    height: 450px;
    object-fit: cover;

    object-position: center;
    object-position: 50% 30%;
  }
}
