@import '../../components/common/colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.Root {
  margin-top: 5%;
  z-index: 1;
}

.Slider {
  margin-top: 5%;

  &__title {
    font-size: 23px;
    color: $base-color;
    // font-family: 'Times New Roman', Times, serif;
  }
}

.Item:hover {
  transition: 0.5s;
  box-shadow:
    0 15px 12px 0 rgba(0, 0, 0, 0.01),
    0 27px 40px 0 rgba(0, 0, 0, 0.01);
}

.Item {
  transition: 0.5s;
  margin-right: 3%;
  // margin-top: 2%;
  margin-bottom: 3%;
  height: 280px;
  display: flex;
  flex-direction: column;

  border: 1px solid rgb(30, 29, 29, 0.15);

  &__details {
    margin: 0;
    padding: 0;
    padding-left: 5%;
    font-size: 14px;
  }

  a {
    text-decoration: none;
    color: black;
  }

  img:hover {
    transition: 0.3s;
    opacity: 0.95;
  }

  img {
    transition: 0.3s;
    width: 100%;
    height: 140px;
    object-fit: cover;
  }

  &__Price {
    margin-top: 0;
    position: absolute;
    bottom: 0;
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-style: normal;
  }

  &__CourseAuthor {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.8);
  }

  &__CourseName {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-style: normal;
    margin-bottom: 0;
  }

  &__Rating {
    margin-top: 5px;
    display: flex;
    align-items: center;

    height: 20px;

    h4 {
      font-size: 11px;
      color: rgba(0, 0, 0, 0.55);
    }

    h3 {
      color: rgb(255, 166, 0);
    }

    img {
      padding: 0 2px;
      width: 13px;
      height: 13px;
    }
  }
}

.dropdownContent {
  position: absolute;
  z-index: 10;
}

.PriceWithDiscount {
  display: flex;

  s {
    font-weight: 400;
    top: 0;
    margin-left: 45px;
  }

  h3 {
    font-size: 17px;
  }

  h5 {
    margin-left: 2%;
    color: rgba(3, 1, 1, 0.8);
  }
}

@media (max-width: 600px) {
  .Item {
    img {
      width: 100%;
      height: 160px;
      object-fit: cover;
    }

    &__Rating {
      margin-top: 0;
      display: flex;
      align-items: center;

      h4 {
        font-size: 11px;
        color: rgba(0, 0, 0, 0.55);
      }

      h3 {
        color: rgb(255, 166, 0);
      }

      img {
        padding: 0 2px;
        width: 13px;
        height: 13px;
      }
    }
  }
}

.AnimationItem {
  width: 19%;
}

.HeadLineAnimation {
  margin-top: 5px;

  * {
    margin-top: 4%;
  }
}

.LoadingAnimation {
  display: flex;
  justify-content: space-between;
  //border: 1px solid red;
  width: 1800px;
}
