@import '../../../../components/common/colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

.Title {
  display: flex;
  width: 100%;
  padding-left: 6%;
  // background-color: $title-course;
  height: 350px;
}
.Title {
  background: linear-gradient(90deg, $title-course 0%, $title-course 70%, $first-color 100%);
}

.Course {
  &__info {
    color: white;
    margin-left: 5%;
  }
}

.CourseGeneral {
  display: flex;
  align-items: center;

  h2 {
    margin-left: 2%;
    font-size: 14px;
  }

  img {
    width: 20px;
    height: 20px;
  }
}

.CourseRating {
  display: flex;
  align-items: center;
  * {
    padding: 0;
    margin: 0;
  }
  img {
    width: 20px;
  }

  h3 {
    margin-left: 5px;
    font-size: 13px;
    color: rgba(166, 166, 166);
  }
}

.CourseAuthor {
  h1 {
    font-family: 'Space Grotesk', sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    // color: rgba(255, 255, 255, 0.8);
    margin-top: 0;
    font-size: 16px;
  }
}

.CourseCaption {
  margin: 0;

  p {
    font-size: 18px;
    width: 100%;
  }
}

.RatingItem {
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
  }
}

.RatingItem:not(:first-child) {
  margin-left: 3%;
}

.Directions {
  * {
    color: white;
  }
  a {
    color: white;
  }
}

.Title {
  @media (max-width: 900px) {
    .Directions {
      h5 {
        margin-bottom: 0;
      }
    }

    .CourseName {
      margin-top: 2%;

      h1 {
        width: 100%;
        margin: 0;
      }
    }

    .CourseCaption {
      margin-top: 2%;

      P {
        width: 100%;
      }
    }

    .Course {
      &__Rating {
        h1 {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.CourseName {
  // border: 1px solid red;
  padding: 0;
  align-items: center;
  display: flex;
  width: 90%;
  min-width: 700px; // todo

  h1 {
    font-size: 40px;
    padding: 0;
    margin: 0;
  }
}

.CourseWithShortName {
  width: 100%;
  margin-top: 0;
  padding-top: 0;

  h1 {
    border: 1px solid rgb(90, 31, 156);
    width: 100%;
    font-size: 24px;
  }
}

.CourseWithLongName {
  width: 100%;
  margin-top: 0;
  padding-top: 0;

  h1 {
    border: 1px solid rgb(90, 31, 156);
    width: 100%;
    font-size: 24px;
  }

  @media (max-width: 900px) {
  }
}

.CourseCaption {
  width: 55%;
  margin: 0;
  font-family: 'Space Grotesk', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

  @media (max-width: 1400px) {
    width: 80%;
  }
}
