.accordionRoot {
  // border: 1px solid rgba(0, 0, 0, 0.5);
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  // display: flex;
  // justify-content: space-around;
  margin-bottom: 1%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.accordionHeader {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  // border: 1px solid red;
  height: 40px;
  gap: 32px;
  cursor: pointer;
}

.accordionContent {
  transition: all ease-in-out 0.3s;
}

.uppercase {
  text-transform: uppercase;
}

.show {
  opacity: 1;
  visibility: visible;
  max-height: 800px;
  margin-top: 32px;
}

.hide {
  opacity: 0;
  visibility: hidden;
  max-height: 0px;
  margin-top: 0px;
}

.iconContainer {
  transition: all ease-in-out 0.3s;
}

.rotate {
  transform: rotate(-180deg);
}

.HeaderOverview {
  // border: 1px solid red;
  width: 100%;
  display: flex;
  justify-content: space-around;
  // text-align: left;
  align-items: center;
}

.HeaderOverview h1,
.HeaderOverview > div,
.HeaderOverview h2 {
  flex: 1;
  text-align: left;
  margin-left: 5%;
}

.TitleNameRoot {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
}

.HeaderOverview h2 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
}
