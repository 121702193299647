@import '../../../../components/common/buttons.scss';

.Root {
  // border: 1px solid rgba(0, 0, 0, 0.1);
  // margin-left: 15%;
  // margin-right: 15%;
}
.InstructorHeader {
  margin-top: 10%;
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 10%;
  display: flex;
}

.InstructorImage {
  width: 30%;

  img {
    object-fit: cover;
    width: 200px;
    height: 200px;
    border-radius: 10rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.Description {
  width: 70%;

  p {
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Times New Roman', Times, serif;
  }
}

.DescriptionStats {
  height: 200px;
  padding: 2%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.015),
    0 17px 50px 0 rgba(0, 0, 0, 0.014);
}

.SubscribeButton {
  @include ButtonFontWeightStyle(300px, 50px, 400);
}

.ProgressItem {
  // border: 1px solid red;

  display: flex;
  align-items: center;

  h4 {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.8);
  }
  h2 {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.8);
  }
}
