.CourseWrapper:hover {
  transition: 0.3s;
  cursor: pointer;
}

.CourseWrapper {
  transition: 0.3s;
  margin-left: 0;
  display: flex;

  img {
    object-fit: cover;
    width: 300px;
    height: 180px;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 5px;
    margin-left: 2%;
    font-size: 20px;
  }

  h2 {
    font-size: 14px;
    margin-left: 2%;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 10px;
    margin-left: 2%;
  }
}

.CourseDescription {
  p {
    margin-top: 0;
    color: rgba(0, 0, 0, 0.8);
    margin-left: 2%;
    font-size: 15px;
    width: 80%;
  }
}

.Course {
  &__properties {
    margin-top: 0;

    h2 {
      margin-top: 0;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.7);
    }
  }

  &__rating {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;

    text-align: center;
    color: orange;

    h3 {
      font-size: 15px;
    }

    img {
      width: auto;
      height: 14px;
    }
  }
}

.Prices {
  display: flex;

  s {
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
    margin-left: 5px;
  }
}

.HeaderWithPrice {
  display: flex;
  justify-content: space-between;

  h2 {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
    font-size: 20px;
    margin-top: 0;
    text-align: end;
  }
}

.CourseAuthor {
  color: rgba(0, 0, 0, 0.8);
  font-size: 11px;
  margin-bottom: 0;
  height: 20px;
}

.CourseTitle {
  margin-bottom: 0;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
}

hr {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.CourseImage {
  height: 100%;
}

@media (max-width: 1000px) {
  .CourseDescription {
    display: none;
  }

  .CourseTitle {
  }

  .CourseWrapper {
    //  border: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  .CourseImage {
    width: 50%;

    img {
      width: 100%;
    }
  }
}
