@import '../../components/common/buttons.scss';

.ApplyForm {
  margin-top: 5%;
  width: 600px;
  height: 600px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-left: auto;
  margin-right: auto;
}

.NotificationForm {
  position: absolute;
  padding: 10px;
  font-size: 8px;
  text-align: left;
  width: 300px;
  margin-left: 2%;
  border-radius: 0.4rem;
  margin-top: 2%;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.5);

  button {
    @include MainButtonStyle(50%, 35px);
  }
}

.Field {
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  h3 {
    font-family: 'Times New Roman', Times, serif;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 5px;
  }

  input:focus {
    outline: none;
  }
  input {
    width: 100%;
    height: 30px;
  }
}

.ApplyProgressContainer {
  width: 100%;
  height: 2px;
  overflow: hidden; /* Ensure the progress bar doesn't overflow its container */
}

.ApplyProgress {
  height: 100%;
  background-color: #121824;
  transition: width 0.5s ease; /* Smooth transition for width changes over 0.5 seconds */
}

.FormBottomButtons {
  margin-top: 4%;
  // border: 1px solid red;
  display: flex;
  justify-content: space-around;

  button {
    @include ButtonFontWeightStyle(35%, 45px, 500);
  }
}

.RootFirstPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
