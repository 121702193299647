@import '../../../components/common/buttons.scss';

.Root {
  margin-left: 10%;
  margin-right: 10%;

  @media (max-width: 1500px) {
    margin: 0;
  }
}

.News {
  margin-top: 2%;
  position: relative;
  width: 100%;
  height: 400px;

  @media (max-width: 1500px) {
    margin-top: 0%;
  }
}

.BackgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire container without distortion */
  display: block;
}

.NewsTextWrapper {
  // border: 1px solid red;
  position: absolute;
  font-family: 'Times New Roman', Times, serif;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(0, 0, 0, 1);
  text-align: left;
  width: 100%;

  h1,
  h4 {
    margin-left: 5%;
  }
}

.RedirectButton {
  margin-left: 5%;
  border-radius: 1rem;

  a {
    color: black;
  }

  @include ButtonFontWeightInterStyle(170px, 45px, 500);
  border: none;
}
