@import '../../components/common/colors.scss';
@import '../../components/common/buttons.scss';
// @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

.Root {
  margin-top: 10%;
  margin-bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Authentication {
  border: 1px solid black;
  border: none;
  padding: 3%;
  border-radius: 0.6rem;

  height: 500px;
  width: 500px;

  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.MenuTitle {
  text-align: center;
}

.LoginButton {
  width: 100%;
  margin-top: 5%;

  button {
    @include ButtonFontWeightStyle(100%, 60px, 500);
    background-color: #121824;
    color: white;
  }
}

.BottomLinks {
  display: flex;
  justify-content: space-around;
  margin-top: 1%;

  a {
    text-decoration: none;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    justify-content: space-around;
    color: #121824c4;
  }
}

.MenuInput {
  width: 100%;
}

.NotificationDiv {
  display: none;
  font-size: 16px;
  text-align: center;
  padding: 5px;
  background-color: #1218240f;
  color: rgba(0, 0, 0, 0.6);
}
