.Root {
  margin-top: 3%;
  margin-left: 10%;
  margin-right: 10%;
}

.Title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 35px;
}

.LastUpdate {
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
  font-size: 20px;
}
