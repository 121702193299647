@import '../../components/common/colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.Header {
  width: 100%;
  height: 80px;
  align-items: center;
  // justify-content: center;
  display: flex;
  box-shadow: 3px 3px 2px 1px rgba(0, 0, 0, 0.1);
}

.logo {
  margin-left: 1%;
  margin-right: 1%;

  display: flex;
  align-items: center;
  img {
    height: auto;
    width: 150px;
  }
}

.HeaderNavigateLinks {
  width: 100%;
  display: flex;
  // justify-content: center;

  a:hover {
    transition: 0.3s;
    color: purple;
  }
  a {
    transition: 0.3s;
    padding-left: 1%;
    padding-right: 1%;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Inter', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }
}

.VerticalLine {
  width: 1px;
  height: 50px;
  background-color: #000;
  border: none;
  margin: 0 10px;
}

.SmallScreenMenu {
  height: 25px;
  width: auto;
  display: none;
  opacity: 0.7;
}

.OpenDrawerButton {
  display: none;
}

.OpenDrawerButton {
  height: 40px;
  width: auto;
  background-color: white;
  border: none;

  &:hover {
    cursor: pointer;
  }

  img {
    opacity: 0.7;
    height: 30px;
    width: auto;
  }
}

.SmallSceenButtons {
  display: flex;
  justify-content: space-around;
  width: 90px;
}

@media (max-width: 600px) {
  .Header {
    justify-content: center;
  }
  .OpenDrawerButton {
    display: block;
  }
  .HeaderNavigateLinks {
    display: none;
  }
}
