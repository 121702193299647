@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

.Root {
  position: relative;
  display: inline-block;
}

.HeaderTitle {
  display: block;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.MainDropDownContant {
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.35);
  display: block; /* Ensure it's block for layout purposes */
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 10;
  width: 200px; /* Adjust width as needed */
  opacity: 0;
  transform: translateY(-10px); /* Start position for the slide effect */
  transition:
    opacity 0.3s ease,
    transform 0.2s ease;
  pointer-events: none; /* Prevent clicks when hidden */

  a {
    color: rgba(0, 0, 0, 1);
  }
}

.Root:hover .MainDropDownContant {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

.SubCategoriesRoot {
  position: relative;
  margin: 0;
  padding: 0;
  // text-align: center;
}

.CategoryTitle {
  display: block;
  padding: 10px;
  text-decoration: none;
  font-size: 15px;
}

.NestedCourseCategories {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 10;
  width: 100%;
  opacity: 0;
  transform: translateY(-10px);
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.1);
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;

  a {
    display: block;
    padding: 5px;
    font-size: 19px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Times New Roman', Times, serif;
  }
}

.Root:hover .MainDropDownContant,
.HeaderTitle:hover + .MainDropDownContant {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto; /* Enable clicks when visible */
}

.SubCategoriesRoot:hover .NestedCourseCategories {
  display: block;
  margin-left: 100%;
  top: 0;
  display: block; // Ensure the block display is applied
  opacity: 1;
  transform: translateY(0); // Final position
}
