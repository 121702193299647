@import '../../../components/common/colors.scss';

.UserInformationWrapper {
  margin-left: 3%;
  width: 25%;
  height: 100%;
  border-right: 1px solid black;
  padding: 10px;

  img {
    border: 1px solid rgba(0, 0, 0, 0.6);
    margin-top: 10%;
    border-radius: 0.5rem;
    width: 150px;
    height: 150px;
    object-fit: cover;
  }

  button:hover {
    transition: 0.2s;
    border-left: 5px solid rgba(0, 0, 0, 0.6);
    background-color: rgb(247, 241, 241);
    cursor: pointer;
  }

  button {
    transition: 0.5s;
    width: 80%;
    height: 30px;
    background-color: white;
    color: black;
    border: none;
    font-size: 13px;
    font-weight: bold;
  }

  &__Social {
    margin-top: 5%;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    &__Link {
      display: flex;
      input {
        height: 30px;
        font-size: 15px;
      }

      img {
        padding: 10px;
        width: 30px;
        height: auto;
      }
    }
  }
}

.User {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 16px;
  }

  h3 {
    margin-top: 0;
    width: 40%;
    background-color: rgb(20, 0, 0);
    color: white;
    padding-top: 2%;
    padding-bottom: 2%;
    border-radius: 0.3rem;
    opacity: 0.8;
    font-size: 13px;
  }
}

.StateWrapper {
  margin-left: 3%;
  text-align: end;
  margin-right: 3%;
  width: 100%;
  border: 1px solid red;

  h1 {
    font-size: 40px;
    margin-bottom: 0px;
    color: black;
  }

  h3 {
    font-size: 22px;
    margin-top: 0px;
  }

  &__Context {
    margin-top: 2%;
    width: 100%;

    h4 {
      text-align: right;
      margin-right: 5%;
      font-size: 30px;
    }
  }

  &__item {
    align-items: center;
    margin-top: 5%;
    margin-left: 1%;
    transition: 0.5s;
    display: flex;
    border-bottom: 1px solid rgb(0, 0, 0, 0.3);

    button {
      height: 60px;
      width: 100%;
    }
  }

  &__ControllButtons {
    li {
      display: flex;
      align-items: center;
    }

    li img {
      width: 15px;
      height: 15px;
    }

    li button:hover {
      cursor: pointer;
      transition: 0.5s;
    }

    li button {
      font-size: 16px;
      transition: 0.5s;
      color: black;
      background-color: rgb(0, 0, 0, 0);
      border: none;
      margin-left: 1%;

      font-weight: bold;
    }

    ul {
      list-style: none;
      padding: 0;
    }
  }
}

.Hrline {
  margin-top: 0;
  margin-left: 5%;
  margin-right: 5%;
}

.CourseContextWrapper {
  height: 100%;

  &__Empty button:hover {
    cursor: pointer;
    transition: 0.5s;
    background-color: $footer;
  }

  &__Empty {
    text-align: center;
    height: 100%;
    text-align: center;
    justify-content: center;
    margin-top: 10%;

    button {
      transition: 0.5s;
      margin-top: 1%;
      background-color: $module-color;
      color: white;
      border: none;
      width: 200px;
      height: 40px;
      font-weight: bold;
    }

    h1 {
      color: rgb(0, 0, 0, 0.4);
    }
  }
}

.Wrapper {
  display: flex;
  border-radius: 1rem;
  text-align: center;
  justify-content: space-between;
  height: 600px;
}
