// @import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap');

.Root {
  // border: 1px solid red;
  margin-top: 5%;
  text-align: center;

  h1 {
    font-weight: 300;
    font-style: normal;
    color: rgba(0, 0, 0, 0.8);
    font-size: 22px;
  }

  hr {
    border: 1px solid rgba(0, 0, 0, 0.09);
    width: 80%;
  }
}
