@import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap');

.UserPersonalData {
  width: 70%;
}

.UserPersonalData,
.UserProgress {
  margin-left: 2%;
}

.AccountImage {
  display: flex;
  flex-direction: column;

  img {
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 200px;
    height: 200px;
    border-radius: 0.3rem;
    object-fit: cover;
  }

  button:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
    transition: 0.5s;
  }

  button {
    position: absolute;
    width: 40px;
    background-color: rgb(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0.1);

    img {
      border: none;
      height: 25px;
      width: auto;
    }
  }
}

.AccountPersonal {
  margin-left: 2%;
  display: flex;
  width: 100%;
}

.Root {
  width: 100%;
  margin-left: 5%;
  margin-bottom: 5%;
}

.WrapperName {
  h1 {
    color: rgba(0, 0, 0, 0.7);
    font-family: 'Zain', sans-serif;
    font-size: 45px;
    margin: 0;
  }

  h3 {
    height: 20px;
    // font-family: 'Zain', sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-top: 0;

    color: rgba(0, 0, 0, 0.6);
    font-size: 15px;
  }
}

.AdvanceRoot {
  display: flex;
}

.UserInformationWrapper {
  margin-right: 3%;
  h1 {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
  }
  h2 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 15px;
  }
}
