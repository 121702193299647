@import '../../../../../components/common/buttons.scss';

.LanguageButton {
  width: 200px;
  border: 1px solid black;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  margin: 2%;

  h1 {
    font-size: 19px;
  }
}

.TranslateButtonWrapper {
  button {
    margin: 1%;
    @include ButtonFontWeightStyle(200px, 40px, 400);
  }
}

.ModalButton:hover {
  background-color: white;
}
.ModalButton {
  border: 1px solid rgba(255, 255, 255, 0.301);
  border-radius: 0;
  width: 150px;
  height: 40px;
  background-color: #121824;
}

.ModalNestedButtons {
  button {
    //  width: 150px;
  }
}
