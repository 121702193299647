@import '../../components/common/buttons.scss';
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

.HelpSeaction {
  margin-top: 5%;
  margin-bottom: 5%;
  // border: 1px solid red;
  display: flex;
  align-items: center;
  @media (max-width: 1000px) {
    flex-direction: column;
    // text-align: center;
    // text-align: center;
  }
}

.HelpMenu,
.HelpText {
  width: 100%;
}

.HelpText {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-family: 'Times New Roman', Times, serif;
    color: rgba(0, 0, 0, 0.8);
    font-size: 20px;
  }
  h1 {
    font-family: 'Times New Roman', Times, serif;
    font-size: 25px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
  }

  img {
    // border: 1px solid red;
    height: 400px;
    width: 400px;
  }
}

.HelpSelections {
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    height: 40px;
    margin: 1%;
    width: 80%;
  }

  textarea {
    width: 80%;
    height: 100px;
  }
}

.HelpMenu {
  text-align: center;
  // border: 1px solid red;

  .dropdownMenu {
    width: 80%;
  }

  button:hover {
    transition: 0.4s;
    cursor: pointer;
    color: white;
    background-color: #121824;
  }
  button {
    background-color: white;
    margin-top: 2%;
    transition: 0.4s;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 200px;
    height: 50px;
  }

  .LookButton {
    @include ButtonFontWeightInterStyle(250px, 45px, 500);
  }

  h1 {
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Times New Roman', Times, serif;
    font-size: 23px;
  }
}
