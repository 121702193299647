*a {
  text-decoration: none;
}

.Root {
  // border: 1px solid red;
  margin-top: 5%;
}

.RootCourse {
  margin-top: 1%; // distance to title
  display: flex;
  margin-bottom: 1px; // otherwise border-bottom is invissible

  &__Price {
    color: rgba(0, 0, 0, 0.8);
    position: absolute;
    margin-bottom: 0;
  }

  &__Description {
    margin-top: 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
  }

  @media (max-width: 1100px) {
    flex-direction: column;

    .Item {
      &__information {
        margin-left: 1%;
      }
      &__Rating {
        height: 30px;
        margin-top: 0;
        margin-bottom: 0;
      }
      &__CourseAuthor {
        margin-bottom: 0;
      }
      &__Price {
        position: relative;
        margin-top: 15px;
      }
    }
    .PriceWithDiscount {
      display: flex;

      h5 {
        padding-left: 5px;
      }
    }
  }
}

.PriceWithDiscount {
  width: 400px;
  display: flex;
}

.CourseTitle {
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 0;
  margin-top: 0;
}

.CourseOldPrice {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;
  margin-left: 18%;
}

.CoursePreview {
  width: 100%;
  min-width: 600px;
  height: 300px;
  object-fit: cover;
}

.CourseWrapperInformation {
  flex-direction: column;
  width: auto;
  margin-left: 2%;
  height: 100%;
  flex: 1;
}

.CourseDescription {
  margin-bottom: 0;
  max-width: 90%;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
  // border: 1px solid red;
}

.CourseAuthor {
  color: rgba(0, 0, 0, 0.8);
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 12px;
}

.CourseRating {
  margin-top: 0;
  display: flex;
  align-items: center;
  // border: 1px solid red;

  h4 {
    margin: 0;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.6);
  }

  h3 {
    margin: 0;
    color: rgb(255, 166, 0);
  }

  img {
    padding: 0 2px;
    width: 15px;
    height: 15px;
  }
}

.SliderTitle {
  margin: 0;
  // border: 1px solid red;
}
