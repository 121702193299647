.BuyPanelSkillsLoadingAnimation {
  width: 90%;
  div {
    margin-top: 1%;
  }
}

.ChainContainer {
  display: flex;
  align-items: center;

  .ChainBox:last-child {
    margin-right: 0;
  }

  .ChainBox:first-child::before {
    display: none;
  }
}

.ChainBox {
  position: relative;
  display: inline-block;
  margin-right: 0px;
  padding: 4px 50px;
  border: 2px solid rgb(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7);
  border-right: none;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -40px;
    transform: translateY(-50%);
    border-width: 21px;
    border-style: solid;
    border-color: transparent transparent transparent rgba(0, 0, 0, 1);
    background-color: white; // Set the inside color to white
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
    border-width: 10px;
    // border-style: solid;
    // border-color: transparent red transparent transparent;
  }
}
