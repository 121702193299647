.HeaderInput {
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  label {
    width: 100%;
    margin-right: 5%;
    position: relative;
  }

  button:hover {
    cursor: pointer;
    background-color: white;
    transition: 0.3s;
    opacity: 1;
  }

  button {
    transition: 0.3s;
    background-color: white;
    position: absolute;
    height: 40px;
    width: 45px;
    border-radius: 2rem;
    border: none;
    opacity: 0.6;

    top: 2px;
    right: 5px;
    bottom: 0;
  }

  input:focus {
    outline: none;
  }

  input {
    padding-left: 10px;
    border-radius: 02rem;
    border: 1px solid rgba(0, 0, 0, 0.5);
    height: 40px;
    width: 100%;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    object-fit: cover;
    width: 20px;
    height: 20px;
  }
}

.SearchInput {
  // border: 1px solid red;
  //  height: 50px;
  width: 100%;
}

.SearchInput {
  // border: 1px solid red;
}

.CompleteSearchInput {
  // border: 1px solid red;
}

.ValueInput {
  // padding: 10px;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  // border: 1px solid red;
  border-radius: 1rem;

  @media (max-width: 900px) {
    display: none;
  }
}

.SearchBarRoot {
  position: relative; /* Set the parent as relative so the button can be positioned inside */
  width: 100%; /* Full width of the container */

  @media (max-width: 1000px) {
    display: none;
  }
}

.InputTodo {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  width: 100%; /* Full width for the input */
  height: 55px;
  padding-right: 60px; /* Add padding to the right to make space for the button */
  border-radius: 2rem;
  border: 1px solid #ccc;
  padding-left: 1rem;

  &:focus {
    outline: none; /* Removes the default outline when focused */
  }
}

.ButtonTodo {
  position: absolute; /* Absolute position to place it inside the input */
  right: 5px; /* Aligns the button to the right inside the input */
  top: 50%; /* Vertical center the button */
  transform: translateY(-50%); /* Adjust for centering */
  //background-color: red;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 0.1rem 0.9rem;
  border-radius: 5rem;
  height: 90%;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    transition: 0.3s;
    border: 1px solid rgba(0, 0, 0, 0.5);
  }

  img {
    opacity: 0.7;
    height: 20px;
    width: auto;
  }
}
