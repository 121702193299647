@import '../../../../../components/common/buttons.scss';

.Root {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 550px;
  width: 450px;
  position: absolute;
  top: 200px;
  right: 11%;
  background-color: white;
  border-radius: 0.2rem;
  z-index: 1;

  hr {
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

.Pricing {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3%;

  s {
    font-size: 14px;
    padding-left: 2%;
  }
  h1 {
    color: rgba(0, 0, 0, 0.9);
  }
}

.Overview {
  margin-left: 5%;

  h2 {
    margin: 0;
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
  }
}

.Payment {
  padding: 5% 5% 2% 5%;

  h1 {
    text-align: center;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.7);
  }
}

.Mics {
  display: flex;
  margin-left: 5%;
  margin-right: 5%;
}

.ShareGift {
  display: flex;
  margin: 0% 3% 0 3%;
}

.CheckoutButton {
  @include ButtonFontWeightStyle(100%, 55px, 500);
  background-color: #121824;
  color: white;
}

.ButtonGift,
.ButtonShare {
  width: 100%;
  height: 40px;
  margin: 2%;
  align-items: center;

  img {
    height: 20px;
    width: auto;
  }
}

.AddCartButton {
  width: 100%;
  height: 40px;
}

.ButtonApplyPromo {
  height: 50px;
  width: 40%;
  margin-left: 2%;
}

.AddWishList:hover,
.ButtonShare:hover,
.ButtonGift:hover,
.ButtonApplyPromo:hover,
.AddCartButton:hover,
.CheckoutButton:hover {
  cursor: pointer;
  background-color: #121824;
  color: white;
  transition: 0.3s;
}

.AddWishList,
.ButtonShare,
.ButtonGift,
.AddCartButton,
.ButtonApplyPromo {
  font-size: 13px;
  // font-weight: bold;
  transition: 0.3s;
  border-radius: 0;
  background-color: white;
  border: 1px solid #121824b0;
}

.AddWishList:hover {
  img {
    filter: invert(100%);
    transition: filter 0.3s ease;
  }
}
.AddWishList {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 50px;
  margin-left: 5%;
  width: 50px;
  margin-left: 5%;

  img {
    object-fit: cover;
    width: 20px;
    height: auto;
  }
}

.Benefits:focus {
  outline: none;
}

.Benefits {
  margin: 0% 5% 0% 5%;

  display: flex;

  input {
    font-weight: 400;
    width: 100%;
    height: 50px;
  }
}

@media (max-width: 1400px) {
  .Root {
    display: none;
  }
}

.SkillsElements {
  margin-top: 1%;
}

.OverviewTitle {
  margin-top: 10px;
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
}

.AddWishListHas:hover {
  cursor: pointer;
}
.AddWishListHas {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 50px;
  margin-left: 5%;
  width: 50px;
  margin-left: 5%;
  background-color: #121824;
  border: none;
  transition: 0.3s;

  img:hover {
    cursor: pointer;
  }
  img {
    transition: 0.3s;
    filter: invert(100%);
    transition: filter 0.3s ease;
    object-fit: cover;
    width: 20px;
    height: auto;
  }
}
