@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap');

@mixin DefaultTimesNewRoman($size, $opacity) {
  font-size: $size;
  font-family: 'Times New Roman', Times, serif;
  color: rgba(0, 0, 0, $opacity);
  margin: 0;
}

@mixin ApplySpaceGroteskFont($size, $weight, $opacity) {
  font-size: $size;
  font-weight: $weight;
  font-family: 'Space Grotesk', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: rgba(0, 0, 0, $opacity);
}

@mixin ApplyZainFont($size, $weight, $opacity) {
  font-size: $size;
  font-weight: $weight;
  font-family: 'Zain', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: rgba(0, 0, 0, $opacity);
}

@mixin ApplySansSerifFont($size, $weight, $opacity) {
  font-size: $size;
  font-weight: $weight;
  font-family: sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: rgba(0, 0, 0, $opacity);
}
