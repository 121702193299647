@import '../../components/common/colors.scss';
@import '../../components/common/buttons.scss';

.Todo {
  margin-top: 10%;
  margin-bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Root {
  border: 1px solid black;
  border: none;
  padding: 3%;
  border-radius: 0.6rem;

  height: 500px;
  width: 500px;

  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.FormTitle {
  text-align: center;
}

.Button {
  margin-top: 5%;
  @include ButtonFontWeightStyle(100%, 60px, 500);
  background-color: #121824;
  color: white;
}

.BackButton {
  background-color: white;
  border: none;
  margin-top: 1%;

  a {
    font-size: 16px;
    font-weight: bold;
    color: #121824c4;
  }
}
