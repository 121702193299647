.dropdownMenu {
  margin-top: 2%;
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.35);
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 150px;
  width: 320px;
  right: 0;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.3);
  transition:
    opacity 0.3s ease,
    transform 0.3s ease; /* Smooth animation */
  transform: translateY(-10px); /* Start off slightly above */
  opacity: 0; /* Start invisible */
  pointer-events: none; /* Prevent interaction when hidden */
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

/* Visible state styles */
.dropdownMenuVisible {
  opacity: 1; /* Make visible */
  transform: translateY(0); /* Slide down to its position */
  pointer-events: auto; /* Enable interaction */
}

/* Image hover effects */
.UserImage img {
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s;
  opacity: 0.7;
  object-fit: cover;
  width: auto;
  height: 25px;
}

.UserImage img:hover {
  opacity: 1;
  transform: scale(1.05); /* Slight zoom effect */
}

/* Button styles inside the dropdown */
.dropdownMenu button {
  background: #000;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  color: #fff;
  padding: 12px 25px;
  border: none;
  cursor: pointer;
}

/* Empty shopping cart styles */
.ShopingCartEmpty {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;

  h1 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 17px;
    font-weight: 400;
  }
}
