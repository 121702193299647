@import '../../components/common/buttons.scss';
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');

.PageTop {
  margin-top: 1%;
  justify-content: space-around;
  align-items: center;
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
  img {
    height: 500px;
    width: auto;
  }
}

.PageTopText {
  h3 {
    font-family: 'EB Garamond', serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    margin: 0;
    color: rgba(0, 0, 0, 0.6);
  }

  h1 {
    width: 100%;
    font-family: 'EB Garamond', serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    color: rgba(0, 0, 0, 1);
  }

  button {
    margin-top: 1rem;
    @include ButtonFontWeightInterStyle(200px, 40px, 600);
  }
}

.HeaderImage {
  border-radius: 130px 120px 270px 120px;
}

.NewRelationsRoot {
  display: flex;
  margin-top: 6rem;
  margin-left: 10%;
  margin-right: 10%;

  h1 {
    width: 100%;
    font-family: 'EB Garamond', serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 30px;
    font-style: normal;
    color: rgba(0, 0, 0, 1);
  }
  h2 {
    font-family: 'EB Garamond', serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 30px;
    font-style: normal;
    margin: 0;
    color: rgba(0, 0, 0, 0.9);
  }
}

.SectionImage {
  height: 400px;
  width: auto;
  border-radius: 1rem;
}

.BottomButton {
  margin-top: 5rem;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ButtonBecame {
  @include ButtonFontWeightInterStyle(300px, 50px, 600);
}
