@import '../../components/common/buttons.scss';

.TopicName {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.AddNoteButton {
  @include ButtonFontWeightInterStyle(250px, 40px, 500);
}
