@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@160..700&display=swap');

.Root {
  margin-bottom: 50px;
  padding: 1rem;
  display: flex;

  p {
    // margin-top: 1rem;
    margin: 0;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.7);
  }

  @media (max-width: 1200px) {
  }
}

.BlogTextWrapper {
  margin-left: 1rem;
}

.BlogPreview {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  width: 450px;
  height: 450px;
  object-fit: cover;
}

.BlogTitle {
  font-family: 'Readex Pro', sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;

  color: rgba(0, 0, 0, 1);
  margin: 0;
  margin-bottom: 1rem;
  font-size: 33px;
}

.BlogDescription {
  margin-top: 1rem;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.BlogCategory {
  margin: 0;
  margin-bottom: 1rem;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 19px;
}

.BlogAuthorRoot {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.WrapperText {
  margin-left: 1rem;
}
.PublishDate {
  margin: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
}
.AuthorName {
  margin: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
}

.InstructorImage {
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 3rem;
}
