.SiteMapRoot {
  margin-top: 10%;
  margin-left: 15%;
  margin-right: 15%;
}

.PagesBranch {
  margin-top: 5%;
}

.Title {
  h1 {
    font-family: 'Times New Roman', Times, serif;
  }
}

.SubTitle {
  h1 {
    color: rgba(0, 0, 0, 0.6);
    font-size: 19px;
    font-family: 'Times New Roman', Times, serif;
  }
}

.LinksGrid {
  display: grid;
  grid-template-columns: repeat(5, 0.2fr);
  grid-template-rows: repeat(2, auto);

  a:hover {
    color: purple;
    transition: 0.3s;
  }
  a {
    transition: 0.3s;
    text-align: left;
    padding: 10px;
    text-decoration: none;
    color: black;
  }
}
