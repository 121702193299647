.HeaderCurriculum details {
  width: 100%;
  text-align: left;
  font-weight: bold;

  summary {
    height: 30px;
    width: 700px;
    color: rgba(0, 0, 0, 0.8);
  }
}

.SegmentTitle {
  margin-top: 2%;
  margin-bottom: 2%;
  background-color: white;
  border-bottom: 1px solid #191f2f23;
  border-right: 1px solid #191f2f23;
  width: 450px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 2px 2px;
  border-radius: 0.5rem;
  text-align: center;

  h2 {
    font-weight: 500;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
  }
}

.HeaderCurriculum details:hover {
  cursor: pointer;
}

.Content {
  display: flex;
  align-items: center;
  padding: 0 10%;
  margin-top: 1%;

  &__topic ul {
    list-style: none;
  }

  &__topic {
    margin-right: 25%;
  }

  &__topic h1 {
    font-size: 20px;
    text-align: left;
    color: black;
  }

  @media (max-width: 900px) {
    width: 100%;
    &__details {
      width: 100%;
    }
  }
}

.TopicItem {
  margin: 5px 5%;
  width: 715px;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding-left: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);

  img {
    margin-right: 10%;
    width: auto;
    height: 20px;
  }

  h1 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    padding-left: 2%;
    text-align: left;
  }

  h5 {
    color: rgba(0, 0, 0, 0.8);
    margin-right: 2%;
  }

  h2 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    padding-left: 5%;
  }
}

// .SummaryTitle:nth-child(1) {
//   justify-content: space-between;;
// }

.SummaryTitle {
  justify-content: space-between;
}
.SummaryTitle {
  align-items: center;
  display: flex;
  h2 {
    text-align: left;
    font-size: 15px;
  }

  img {
    margin-right: 5%;
    width: 30px;
    height: auto;
  }
}

@media (max-width: 1600px) {
  .HeaderCurriculum {
    // border: 1px solid red;
  }
  .SegmentTitle {
    margin-top: 5%;
    width: 100%;
    // border: 1px solid red;
  }
}

.SkeletonContentAnimation {
  margin-bottom: 1%;
}

.NestedModuleRoot {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 2%;
}

.NestedModuleRoot h1 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 20px;
  font-weight: 400;
  flex: 1;
  padding-left: 5%;
  text-align: left;
  margin: 0;
}

.NestedModuleRoot h1:first-of-type {
  flex: 2;
}

.NestedModuleRoot h1:last-of-type {
  flex: 1;
}

.NestedModuleRoot h1:first-of-type {
  width: 70%;
}

.NestedModuleRoot h1:last-of-type {
  width: 30%;
}
