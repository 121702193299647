@import url('../../components/common/colors.scss');

.Header {
  border-bottom: 1px solid black;

  ul {
    list-style: none;
    display: inline-block;
    display: flex;
    width: 100%;

    button {
      font-weight: bold;
      background-color: #1e2025;
      color: white;
      border: none;
      width: 150px;
      height: 50px;
    }

    li img {
      width: 100px;
      height: auto;
    }
  }
}

.Wrapper {
  h1 {
    font-size: 25px;
    margin-left: 5%;
    color: rgba(0, 0, 0, 0.7);
  }
}

.Content {
  margin-left: 20%;
  margin-right: 20%;
  text-align: center;

  h2,
  h3 {
    color: rgba(0, 0, 0, 0.8);
  }
}

.Panel {
  margin-top: 5%;
  display: flex;
  justify-content: space-around;

  button:hover {
    cursor: pointer;
  }

  button {
    font-weight: bold;
    background-color: #1e2025;
    color: white;
    border: none;
    width: 150px;
    height: 50px;
  }
}
