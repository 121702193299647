.Header {
  display: flex;
  margin: 2%;
  align-items: center;
  padding-bottom: 1%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);

  img {
    border-radius: 8rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 150px;
    width: 150px;
    object-fit: cover;
  }

  h1 {
    font-size: 25px;
    margin-left: 1%;
    color: rgba(0, 0, 0, 0.7);
  }
}

.UserCourses {
  margin-left: 2%;
  display: flex;
  height: 500px;
}

.UserCourse:hover {
  img {
    transition: 0.3s;
    opacity: 0.95;
  }
}
.UserCourse {
  // border: 1px solid rgba(0,0,0,0.3);
  padding-right: 1%;
  width: 350px;

  a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.9);
  }

  img {
    transition: 0.3s;
    opacity: 1;
    height: 200px;
    width: 100%;
    object-fit: cover;
  }

  h1 {
    margin: 0;
    font-size: 20px;
  }
}

.Root {
  // border: 1px solid red;
  margin-left: 10%;
  margin-right: 10%;
}
