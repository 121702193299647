.LayoutComponent:hover {
  // border: 1px solid rgba(0, 0, 0, 0.4);
  transition: 0.3s;
  // background-color: rgb(243, 243, 243);

  img {
    transition: 0.3s;
    opacity: 1;
  }
  h2 {
    transition: 0.3s;
    color: black;
  }
}
.LayoutComponent {
  border: 1px solid rgba(0, 0, 0, 0);
  transition: 0.3s;
  border-radius: 1rem;
  // width: 200px;
  display: flex;
  align-items: center;

  img {
    opacity: 0.5;
    height: 20px;
    width: auto;
  }

  h2 {
    font-family: 'Courier New', Courier, monospace;
    margin-left: 10px;
    transition: 0.3s;
    color: rgba(0, 0, 0, 0.5);
    font-size: 15px;
  }
}
