@import '../../components/common/buttons.scss';

.Wrapper {
  display: flex;
  height: auto;

  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2%;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column-reverse;

    .CoursesListItems {
      width: 100%;
    }
  }
}

.CoursesListItems {
  height: 900px;

  @media (max-width: 1200px) {
    //  border: 1px solid red;
    width: 100%;
  }
}

.CoursesForm {
  width: 100%;
  height: 400px;
  margin-bottom: 5%;

  @media (max-width: 900px) {
    width: 100%;
  }
}

.CoursesListItems {
  width: 70%;

  a {
    button {
      @include MainButtonStyle(200px, 50px);
    }
  }
}

.PriceDeleteWrapper {
  margin-right: 3%;
  display: flex;
  align-items: center;
}

.PriceWrapper {
  justify-content: space-between;
  display: flex;
}

.Price {
  display: flex;

  h3 {
    margin: 0;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
  }

  s {
    font-size: 14px;
  }

  h1 {
    font-weight: 400;
    font-size: 23px;
    color: rgba(0, 0, 0, 0.9);
  }
}

.DeleteButton {
  button:hover {
    cursor: pointer;
    background-color: #1c2536;
    color: white;
    transition: 0.3s;
    border-radius: 1rem;
  }
  button {
    width: 30px;
    height: 30px;
    transition: 0.3s;
    border: none;
    // font-weight: bold;
    background-color: white;
  }
}

.DataWrapper {
  display: flex;
  width: 50%;
}

.CourseItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 2%;

  img {
    width: 150px;
    height: 100px;
    border-radius: 0.3rem;
    object-fit: cover;
  }
}

.CourseData {
  margin-left: 2%;

  h1 {
    font-size: 15px;
    margin: 0;
  }

  h3 {
    margin-top: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
  }
}

.CheckoutTitle {
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
}

.Title {
  width: 100%;
  // border: 1px solid red;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.Author {
  // width: 100%;
  // border: 1px solid red;
}

.CourseRating {
  display: flex;
  align-items: center;
  // border: 1px solid red;
  height: 30px;

  h4 {
    margin-right: 2%;
    color: orange;
  }

  h5 {
    color: rgba(0, 0, 0, 0.7);
    margin-left: 2%;
    font-size: 11px;
  }
}

.CourseRatingStar {
  display: flex;
  align-items: center;

  img {
    width: 16px;
    height: auto;
  }
}
