.BigSliderRoot {
  // border: 1px solid red;
  width: auto;
  display: flex;
  height: auto;
}
.BigSliderWrapContext {
  margin-left: 3%;
}

.Line {
  margin-top: 10px;
}

.SmallLine {
  margin-top: 0;
  margin-bottom: 0;
}
