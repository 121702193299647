@import '../../../components/common/colors.scss';
// @import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap');

.UserInformationWrapper {
  width: 25%;
  height: 100%;
  border-right: 1px solid rgba(166, 166, 166, 0.5);
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 4px 0px 4px;

  img {
    object-fit: cover;
    border: 1px solid rgba(0, 0, 0, 0.3);
    margin-top: 10%;
    border-radius: 0.5rem;
    width: 150px;
    height: auto;
  }

  button:hover {
    transition: 0.3s;
    border-left: 5px solid rgba(166, 166, 166);
    background-color: rgba(166, 166, 166, 0.116);
    cursor: pointer;
  }

  button {
    transition: 0.3s;
    width: 80%;
    height: 30px;
    border-left: 5px solid white;
    background-color: white;
    color: black;
    border: none;
    font-size: 13px;
    font-weight: bold;
  }

  &__Social {
    margin-top: 5%;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    &__Link {
      display: flex;
      input {
        height: 30px;
        font-size: 15px;
      }

      img {
        padding: 10px;
        width: 30px;
        height: auto;
      }
    }
  }
}

.User {
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.8);
  }

  h3 {
    margin-top: 0;
    width: 40%;
    background-color: rgb(53, 54, 49);
    color: white;
    padding-top: 2%;
    padding-bottom: 2%;
    border-radius: 1rem;
    opacity: 0.8;
    font-size: 1px;
  }
}

.StateWrapper {
  margin-left: 3%;
  text-align: end;
  margin-right: 3%;
  width: 100%;
  border: 1px solid red;

  h1 {
    font-size: 40px;
    margin-bottom: 0px;
    color: black;
  }

  h3 {
    font-size: 22px;
    margin-top: 0px;
  }

  &__Context {
    margin-top: 2%;
    width: 100%;

    h4 {
      font-family: 'Times New Roman', Times, serif;
      text-align: right;
      margin-right: 5%;
      font-size: 30px;
      color: rgb(0, 0, 0, 0.8);
    }
  }

  &__Nested {
    display: flex;

    img {
      padding-right: 5px;
      width: 20px;
      height: 20px;
    }
  }

  &__item {
    display: flex;
    margin-top: 5%;
    margin-left: 1%;
    transition: 0.5s;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    img {
      border: none;
      opacity: 0.6;
      width: 20px;
    }

    button {
      display: flex;
      align-items: center; /* Align items vertically */
      justify-content: start;

      color: rgba(0, 0, 0, 0.8);
      height: 60px;
      width: 100%;
    }
  }

  &__ControllButtons {
    li {
      display: flex;
      align-items: center;
    }

    li img {
      width: 15px;
      height: 15px;
    }

    li button:hover {
      cursor: pointer;
      transition: 0.5s;
    }

    li button {
      font-size: 16px;
      transition: 0.5s;
      color: black;
      background-color: rgb(0, 0, 0, 0);
      border: none;
      margin-left: 1%;

      font-weight: bold;
    }

    ul {
      list-style: none;
      padding: 0;
    }
  }
}

.Hrline {
  margin-top: 0;
  margin-left: 5%;
  margin-right: 5%;
}

.Wrapper {
  display: flex;
  height: 900px;
}

.Account {
  button:hover {
    cursor: pointer;
    background-color: $footer;
    color: white;
    transition: 0.5s;
  }

  button {
    width: 150px;
    height: 40px;
    transition: 0.5s;
    border: 1px solid rgb(0, 0, 0, 0.5);
    // font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
    background-color: white;
  }
}

.AccountImage {
  display: flex;
  flex-direction: column;

  img {
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 250px;
    height: 250px;
  }

  button:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
    transition: 0.5s;
  }

  button {
    position: absolute;
    width: 40px;
    background-color: rgb(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0.1);

    img {
      border: none;
      height: 25px;
      width: auto;
    }
  }
}

.AccountBottomPanel {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;

  button {
    width: 20px;
    height: 45px;
    margin-top: 1%;
  }

  h1 {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Times New Roman', Times, serif;
  }

  textarea {
    width: 70%;
    height: 300px;
    padding: 8px 8px;
    box-sizing: border-box;
    border: 1px solid rgb(0, 0, 0, 0.2);

    font-weight: bold;
    outline: none;
    background-color: rgb(250, 250, 250);
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    resize: none;
  }
}

.UserPersonalData,
.UserProgress {
  h1 {
    color: rgba(0, 0, 0, 0.7);
    font-size: 18px;
    margin: 0;
    margin-bottom: 10px;
  }

  h3 {
    height: 20px;
    // font-family: 'Zain', sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: 0;
    color: rgba(0, 0, 0, 0.6);
    font-size: 15px;
  }
}

.UserPersonalData {
  // border: 1px solid red;
  width: 20%;
  margin-left: 1%;
}

.SectionTitle {
  display: flex;
  align-items: center;

  h2 {
    // font-family: 'Zain', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }
}

.HorizontalRule {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  margin: 10px 0;
  margin-left: 5px;
}

.UserProgress {
  margin-left: 1%;
  // border: 1px solid red;
  width: 20%;
}

.CalendarHeatMap {
  // border: 1px solid red;
  width: 1000px;
}
.react-calendar-heatmap .color-scale-1 {
  fill: #d6e685;
}
