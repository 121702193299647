.Root {
  display: flex;
  margin-top: 1%;
  width: 100%;
}

.Player {
  video {
    width: 100%;
    height: 700px;
  }
}
