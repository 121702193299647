@import '../../components/common/colors.scss';

.Forms {
  display: flex;
  align-items: center;
  width: 100%;
}

.Form {
  width: 70%;
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;
  color: $background;

  &__area {
    display: flex;
    flex-direction: column;

    input:focus {
      border: none;
    }

    input {
      outline: none;
      margin-top: 2%;
      height: 40px;
      width: 80%;
      padding: 0 10px;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }

    button:hover {
      transition: 0.4s;
      cursor: pointer;
    }

    button {
      margin-left: 10%;
      border: 1px solid rgba(0, 0, 0, 0.2);
      background-color: $footer;
      color: white;
      font-weight: bold;
      border-radius: 0.2rem;
      margin-top: 3%;
      width: 80%;
      height: 50px;
    }

    h1 {
      font-size: 10px;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

.FormDesc {
  width: 100%;
  margin-left: 5%;

  h1 {
    font-family: 'Times New Roman', Times, serif;
  }

  ul {
    img {
      width: 20px;
      height: auto;
    }
  }

  ul li {
    list-style: none;
  }
}
