.Directions {
  margin-top: 1%;
  * {
    color: white;
    font-weight: bold;
  }
  a:hover {
    transition: 0.3s;
    color: rgb(238, 238, 238);
  }
  a {
    transition: 0.3s;
    color: white;
  }
  .ant-breadcrumb-separator {
    color: white; // Change this to your desired color
  }
}
