@import '../../components/common/buttons.scss';

.Introduction {
  margin-top: 4%;
  display: flex;
}

.IntroductionText {
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;

  h1 {
    font-family: 'Times New Roman', Times, serif;
    width: 70%;
    font-size: 40px;
    margin: 0;
  }
  h2 {
    font-family: 'Times New Roman', Times, serif;
    color: rgba(0, 0, 0, 0.5);
    font-size: 25px;
  }

  button {
    @include ButtonFontWeightStyle(300px, 50px, 500);
    color: white;
    background-color: #121824;
  }
}

.IntroductionAssets {
  margin-right: 10%;
  display: flex;
  justify-content: center;

  img {
    // width: 100%;  // Ensures the image takes up the full width of its container
    // height: auto; // Maintains the aspect ratio of the image
    // max-width: 100%; // Prevents the image from growing larger than its original size
    // object-fit: cover;

    object-fit: contain;
    height: 500px;
    width: auto;
  }
}
