@import '../../../components/common/colors.scss';

.Wrapper {
  display: flex;
  margin-bottom: 3%;
  font-family: 'Times New Roman', Times, serif;
}

.custom_file_input {
  display: inline-block;
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

/* Style for when the user hovers over the label */
.custom_file_input:hover {
  background-color: #2980b9;
}

.Menu {
  width: 30%;
}
.Content {
  width: 100%;
}

.Content {
  color: rgba(0, 0, 0, 0.8);
  height: auto;
  min-height: 700px;
  padding: 10px 50px;
  margin-right: 10%;
  border: none;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 12px 00px rgba(0, 0, 0, 0.5);
}

.Description {
  h2 {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
  }

  input:focus {
    border: none;
  }

  h2 {
    width: 70%;
  }

  button:hover {
    cursor: pointer;
  }
}
.nextButton {
  padding: 0;
  margin: 150px 0;
  margin-left: 85%;
  width: 150px;
  height: 50px;
  font-size: 17px;
  background-color: $footer;
  color: white;
  border: none;
}

.input_title {
  outline: none;
  overflow: hidden;
  resize: none;
  min-width: 50%;
  min-height: 20px;
  padding: 8px;
  font-size: 13px; // Adjust font size as needed
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid #ccc; // Add a border for better visibility
  border-radius: 4px; // Add border-radius for rounded corners
}

.input_subtitle {
  outline: none;
  overflow: hidden;
  resize: none;
  min-width: 50%;
  min-height: 70px;
  padding: 8px;
  font-size: 13px; // Adjust font size as needed
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid #ccc; // Add a border for better visibility
  border-radius: 4px; // Add border-radius for rounded corners
}

.Menu {
  color: rgba(0, 0, 0, 0.8);

  &__point {
    background-color: $footer;
    border-radius: 0.1rem;
    height: 15px;
    width: 15px;
  }

  &__item {
    display: flex;
    align-items: center;
  }

  ul {
    li {
      h2 {
        margin-bottom: 5px;
      }
      & {
        padding: 2px 10px;
        list-style: none;
      }

      button:hover {
        cursor: pointer;
        background-color: rgb(0, 0, 0, 0.1);
        transition: 0.2s;
      }

      button {
        color: rgba(0, 0, 0, 0.8);
        transition: 0.2s;
        font-family: 'Times New Roman', Times, serif;
        font-weight: bold;
        font-size: 16px;
        width: 180px;
        height: 40px;
        border: none;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: white;
      }
    }
  }
}

.Add {
  margin-top: 2%;
  width: 300px;
  border: none;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: rgba(0, 0, 0, 0);
}

.Side {
  img {
    width: 300px;
    height: auto;
  }
}

.Topics {
  display: flex;

  input {
    margin-top: 5px;
    color: black;
  }

  h2,
  h3 {
    width: 90%;
    color: rgba(0, 0, 0, 1);
  }

  button:hover {
    cursor: pointer;
  }
}

.Pricing {
  h4 {
    color: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 10px 10px;
  }
}

.Subtitle {
  width: 60%;
  font-size: 15px;
}
.Title {
  font-size: 22px;
}

.Checkbox input:checked {
  background-color: red; /* Change the background color as needed */
}

.Checkbox label {
  margin-left: 8px; /* Adjust the margin as needed */
}

.Checkbox input:checked + label {
  /* Add styles for the label when the checkbox is checked */
  font-weight: bold;
  color: #007bff; /* Change the color as needed */
}

.Checkbox:checked {
  background-color: red;
}

.Checkbox {
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  input:checked {
    background-color: red;
  }

  input {
    width: 16px;
    height: 16px;
    margin: 0;
  }
}
