.Header {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 3px 3px 2px 1px rgba(0, 0, 0, 0.2);
  height: 80px;
  display: flex;
  align-items: center;

  h2 {
    color: rgba(0, 0, 0, 1);
    font-size: 17px;
    margin-left: 1%;
  }
}

.Logo {
  margin-left: 1%;

  img {
    height: 60px;
    width: auto;
  }
}

.VerticalLine {
  width: 1px;
  height: 50px;
  background-color: #000;
  border: none;
  margin: 0 10px;
}
