@import '../../components/common/colors.scss';

.Root {
  display: flex;
}

.TabSwitcher {
  border: 1px solid red;
  display: flex;

  button:hover {
    cursor: pointer;
  }

  button {
    border: none;
    background-color: white;
    color: black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }
}

.VideoPlayerWrapper {
  margin-left: 1%;
  width: 100%;
}
