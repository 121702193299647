.accordionRoot {
  width: 100%;
  // border: 1px solid rgba(0,0,0,0.2);
  padding-top: 15px;
  padding-bottom: 15px;
}

.accordionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  cursor: pointer;
}

.accordionContent {
  transition: all ease-in-out 0.3s;
}

.uppercase {
  text-transform: uppercase;
}

.show {
  opacity: 1;
  visibility: visible;
  max-height: 800px;
  margin-top: 32px;
}

.hide {
  opacity: 0;
  visibility: hidden;
  max-height: 0px;
  margin-top: 0px;
}

.iconContainer {
  transition: all ease-in-out 0.3s;
}

.rotate {
  transform: rotate(-180deg);
}
