@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

.Author {
  width: 500px;
  border: 1px solid red;
}

.Stats {
  margin-left: 4%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.AuthorNested {
  display: flex;

  h1,
  h2 {
    width: 100%;
    margin-left: 10px;
  }

  h1 {
    margin-bottom: 0;
  }

  h2 {
    margin-top: 0;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.8);
  }
}

.AuthorRating {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-top: 0;

  img {
    width: 14px;
    height: 14px;
  }
}

.AboutAuthorName {
  border: 1px solid red;
  text-align: left;

  h1 {
    font-size: 25px;
  }
  h3 {
    margin: 0;
  }
}

.StatsEntity {
  display: flex;
  width: 100%;
  align-items: center;
  height: 30px;
  // border: 1px solid red;

  h4 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 11px;
    margin-left: 2%;
  }

  h2 {
    margin-right: 3%;
  }
}

.TotalRating {
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 5rem;
  height: 100px;
  width: 100px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 10%;

  h1 {
    font-size: 14px;
  }
}

.AuthorImage {
  img {
    border: 1px solid rgba(0, 0, 0, 0.05);
    object-fit: cover;
    width: 150px;
    height: 150px;
    border-radius: 5rem;
  }
}

.AuthorsGeneral {
  display: flex;
}

.AuthorName {
  margin-bottom: 1%;
  h1 {
    margin: 0;
  }
  h2 {
    color: rgba(0, 0, 0, 0.7);
    font-size: 17px;
    margin: 0;
  }
}

.ProgressItem {
  // border: 1px solid red;
  // width: 350px;
  height: 30px;
  display: flex;
  align-items: center;

  h4 {
    margin-left: 2%;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.8);
  }
  h2 {
    margin-right: 2%;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.8);
  }
}

.AuthorBio {
  // width: 80%;
  font-size: 18px;
  font-family: 'Space Grotesk', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.AuthorSection {
  margin-top: 5%;
}

.InstructorProgressSection {
  // border: 1px solid red;
  margin-left: 5%;
  width: 100%;
}

.InstructorBasicSection {
  margin-left: 2%;
  width: 30%;
  // border: 1px solid red;

  h1 {
    font-size: 15px;
  }
}

.NestedBasic {
  display: flex;
  align-items: center;
  margin: 0;
  // border: 1px solid red;
  height: 30px;

  h1 {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
  }
  h2 {
    color: rgba(0, 0, 0, 0.8);
    margin-left: 1%;
    font-size: 17px;
  }
}
