@import '../../components/common/colors.scss';
@import '../../components/common/buttons.scss';

.Todo {
  margin-top: 10%;
  margin-bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Root {
  border: 1px solid black;
  // margin: 10% 30% 10% 30%;
  border: none;
  padding: 3%;
  border-radius: 0.6rem;

  height: 500px;
  width: 500px;

  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  position: relative;

  button {
    margin-top: 10px;
    @include ButtonFontWeightStyle(100%, 60px, 500);
    background-color: #121824;
    color: white;
  }
}

.ReadTermsSection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;

  h2 {
    margin: 0;
    font-family: 'Times New Roman', Times, serif;
    color: rgba(0, 0, 0, 0.7);
    font-size: 13px;

    a {
      color: purple;
      border-bottom: 1px solid purple;
    }
  }
}

.NestedForm {
  width: 75%;

  a {
    text-decoration: none;
    width: 100%;
    text-align: center;
  }
}

.FormTitle {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.8);
}

.PasswordRequirements {
  h2 {
    margin-bottom: 0;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
  }

  h3 {
    margin: 0;
    margin-top: 2px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.7);
  }
}

.AuthLink {
  margin-top: 2%;
  text-align: center;
  font-size: 15px;

  a {
    color: black;
  }
}

.MenuInput {
  input:focus {
    outline: none;
  }
}

.RegistrationButton {
  width: 100%;

  button {
    @include ButtonFontWeightStyle(100%, 60px, 500);
    background-color: #121824;
    color: white;
  }
}

.Purple {
  color: purple;
  border-bottom: 1px solid purple;
}

.MenuTitle {
  // border: 1px solid red;
  // margin-top: 0;
}
