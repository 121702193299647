@import '../../components/common/colors.scss';

.Wrapper {
  display: flex;
  margin-top: 5%;

  border-radius: 0.2rem;

  button:hover {
    cursor: pointer;
    transition: 0.6s;
    background-color: $title-course;
    color: white;
  }

  textarea:focus {
    border: none;
    outline: none;
  }

  textarea {
    padding: 10px 10px;
    border: none;
    outline: none;
    width: 600px;
    height: 500px;
    margin-left: 10%;
    box-shadow: 3px 3px 5px 3px rgba(0, 0, 0, 0.3);
    resize: none;
    overflow: hidden;
  }

  button {
    margin-left: 50%;
    transition: 0.6s;
    height: 45px;
    width: 150px;
    margin-top: 2%;
    margin-bottom: 5%;
    font-weight: bold;
    border: none;
    background-color: $footer;
    color: white;
  }
}

.Tips {
  width: 100%;
}
.Input {
  width: 100%;
}

.Tips {
  margin: 20px; /* Adjust the margin as needed */

  details {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    transition: height 0.3s ease; /* Add smooth transition to height changes */

    summary {
      cursor: pointer;
      font-weight: bold;
    }

    &[open] {
      height: auto; /* Set to 'auto' for dynamic height based on content */
    }

    & > details {
      margin-top: 10px; /* Adjust margin between nested details */
    }
  }

  @media (max-width: 768px) {
    details {
      width: 100%;
    }
  }
}

.IssueRoot {
  width: 400px;
  height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  h1 {
    color: rgba(0, 0, 0, 0.8);
  }
}

.PageBottom {
  margin-bottom: 5%;

  h1,
  h2 {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
  }

  text-align: center;
}

.QuestionsRoot {
  margin-bottom: 5%;
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 10%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: space-between;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.WrapperIssueCard {
  margin-left: 5%;

  p {
    color: rgba(0, 0, 0, 0.8);
  }
}
