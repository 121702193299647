.NotificationsRoot {
  width: 100%;
  margin-left: 5%;
  margin-right: 5%;
}

.Notification {
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-top: 1%;
  // padding: 1%;
}
