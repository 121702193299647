.Root {
  position: fixed;
  width: 1000px;
  height: 500px;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.Review {
  // border: 1px solid red;
  width: 70%;
}
.ReviewUser {
  display: flex;
  align-items: center;
}

.UserReviewImage {
  height: 50px;
  width: auto;
  border-radius: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.UserReviewName {
  margin-left: 1%;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
}

.ReviewContext {
  p {
    // font-family: 'Montserrat Alternates', sans-serif;
    font-size: 19px;
  }
}

.ReviewBottomButtons {
  display: flex;
}

.ComplainButton:hover {
  cursor: pointer;

  h2 {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}

.ComplainButton {
  background-color: white;
  border: none;

  img {
    height: 25px;
    width: auto;
  }

  h2 {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 51%;
    left: 14%;
    transform: translateX(10px);
    color: black;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 3px;
    white-space: nowrap;
    z-index: 10;
    transition:
      opacity 0.5s ease,
      visibility 0.5s ease;
  }
}

.RatingReviewsButtons {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  button:hover {
    cursor: pointer;
    transition: 0.3s;
    background-color: aquamarine;
  }
  button {
    transition: 0.3s;
    width: 50px;
    border: none;
    background-color: white;
  }
}

.MenuHeader {
  h1 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 25px;
  }
}

.UserRating {
  img {
    width: auto;
  }
}
