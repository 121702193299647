.Root {
  position: fixed;
  right: 0;
  bottom: 0;
  margin-right: 2%;
  margin-bottom: 2%;
  z-index: 5;
}

.ChatBotOpenButton:hover {
  cursor: pointer;
}
.ChatBotOpenButton {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2rem 2rem 0 2rem;
  width: 180px;
  height: 60px;
}

.OpenButtonNested {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  h1 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
  }
}

.ChatBotLogo {
  height: 30px;
  width: auto;
}
