.Description {
  display: flex;

  &__content {
    width: 100%;
    color: rgba(0, 0, 0, 0.8);
    height: auto;
    min-height: 700px;
    padding: 10px 50px;
    margin-top: 2%;
    margin-right: 10%;
    margin-left: 2%;
    margin-bottom: 2%;
    border: none;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 12px 00px rgba(0, 0, 0, 0.5);

    h2 {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.7);
    }

    input:focus {
      border: none;
    }

    h2 {
      width: 70%;
    }

    button:hover {
      cursor: pointer;
    }
  }
}

.input_title {
  outline: none;
  overflow: hidden;
  resize: none;
  min-width: 50%;
  min-height: 20px;
  padding: 8px;
  font-size: 13px; // Adjust font size as needed
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid #ccc; // Add a border for better visibility
  border-radius: 4px; // Add border-radius for rounded corners
}

.input_subtitle {
  outline: none;
  overflow: hidden;
  resize: none;
  min-width: 50%;
  min-height: 70px;
  padding: 8px;
  font-size: 13px; // Adjust font size as needed
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid #ccc; // Add a border for better visibility
  border-radius: 4px; // Add border-radius for rounded corners
}
