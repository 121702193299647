.Income {
  width: 20%;
  height: 150px;
  border: 1px solid #e1e1f5;
  border-radius: 0.6rem;
  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 6px 20px 0 rgba(0, 0, 0, 0.2);
  border-left: 6px solid #e1e1f5;

  &__title {
    padding-left: 5%;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.5);
  }

  &__collect {
    display: flex;
    text-align: left;
    align-items: center;

    h3 {
      margin-bottom: 0;
      padding-left: 2%;
      font-size: 10px;
      opacity: 0.7;
    }

    h2 {
      padding-left: 2%;
      font-size: 17px;
    }
  }

  &__statistic {
    display: flex;
    text-align: left;
    align-items: center;
  }

  &__Preview {
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
    }
  }

  &__statistic h3 {
    padding-left: 2%;
    font-size: 16px;
    color: rgb(97, 231, 131);
  }

  &__statistic h2 {
    padding-left: 5%;
    font-size: 16px;
  }
}

.Students {
  width: 20%;
  height: 150px;
  border: 1px solid #e1e1f5;
  border-radius: 0.6rem;
  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 6px 20px 0 rgba(0, 0, 0, 0.2);
  border-left: 6px solid #e1e1f5;

  &__title {
    padding-left: 5%;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.5);
  }

  &__statistic {
    display: flex;
    text-align: left;
    align-items: center;
  }

  &__statistic h3 {
    padding-left: 2%;
    font-size: 16px;
    color: rgb(97, 231, 131);
  }

  &__statistic h2 {
    padding-left: 5%;
    font-size: 16px;
  }

  &__content h3 {
    padding-left: 2%;
    padding-right: 2%;
    font-size: 10px;
    opacity: 0.7;
  }

  &__content h2 {
    font-size: 17px;
  }

  &__content {
    display: flex;
    text-align: left;
    align-items: center;
  }
}

.Rating {
  width: 20%;
  height: 150px;
  border: 1px solid #e1e1f5;
  border-radius: 0.6rem;
  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 6px 20px 0 rgba(0, 0, 0, 0.2);
  border-left: 6px solid #e1e1f5;

  &__title {
    padding-left: 5%;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.5);
  }

  h2 {
  }
}
