@import '../../components/common/colors.scss';

.Header {
  width: 100%;
  height: 80px;
  align-items: center;
  // justify-content: center;

  display: flex;
  box-shadow: 3px 3px 2px 1px rgba(0, 0, 0, 0.2);

  h2 {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.9);
  }
}

.logo {
  margin-left: 1%;

  img {
    height: 60px;
    width: auto;
  }
}

.VerticalLine {
  width: 1px;
  height: 50px;
  background-color: #000;
  border: none;
  margin: 0 10px;
}
