@import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap');

@media (max-width: 1000px) {
  .Info__graphic {
    display: none;
  }
}

.Info {
  margin-top: 5%;
  display: flex;
  align-items: center;

  p {
    font-family: 'Zain', sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-top: 0;
  }

  &__number {
    display: flex;
    justify-content: left;
    align-items: center;

    h2 {
      position: relative;
      float: left;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__block {
    display: flex;
    justify-content: left;
    align-items: center;

    h1 {
      color: rgba(0, 0, 0, 0.9);
      // border-bottom: 2px solid #1d1a30da;
      text-align: center;
      margin-left: 2%;
    }
  }

  @keyframes fadeInUpB {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__content ul {
    list-style: none;
  }

  &__graphic,
  &__content {
    width: 100%;
  }

  &__graphic img {
    right: 0;
    width: 100%;
    height: auto;
  }
}

.PNumber {
  background-color: #1d1a30;
  width: 40px;
  height: 40px;
  border-radius: 5rem;

  color: white;
  font-size: 25px;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
